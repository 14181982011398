import React, { useCallback, useEffect } from 'react';
import { colors, media, styled } from 'styles';
import { useMvpBooksTracker } from '../hooks/useMVpBooksTracker';
import { section, enunciado } from '../images';
import { useUploadDialogContext } from '../providers/UploadDialogProvider';

export function ChooseListTypeStep({ origin }) {
  const { setStep, setStatementList } = useUploadDialogContext();
  const { trackViewListTypeScreen, trackClickListType } = useMvpBooksTracker();

  useEffect(() => {
    trackViewListTypeScreen(origin);
  }, [trackViewListTypeScreen, origin]);

  const handleClickListType = useCallback(
    (type, nextStep) => {
      trackClickListType({
        type,
      });
      setStatementList(type === 'Enunciado' ? true : false);
      setStep(nextStep);
    },
    [setStatementList, setStep, trackClickListType]
  );

  return (
    <Container>
      <Title>
        RESOLVEMOS SUA LISTA
        <br /> EM 45 SEGUNDOS!
      </Title>
      <Subtitle>Qual é o seu tipo de Lista?</Subtitle>
      <InstructionContainer>
        <Instruction onClick={() => handleClickListType('Enunciado', 2)}>
          <ImgContainer>
            <InstructionImage src={enunciado} />
          </ImgContainer>
          <NextButton onClick={() => handleClickListType('Enunciado', 2)}>
            Lista com enunciado de questão
          </NextButton>
        </Instruction>
        <Instruction onClick={() => handleClickListType('Seção', 2)}>
          <ImgContainer>
            <InstructionImage src={section} />
          </ImgContainer>
          <NextButton onClick={() => handleClickListType('Seção', 2)}>
            Lista com seção do livro e número de exercício
          </NextButton>
        </Instruction>
      </InstructionContainer>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImgContainer = styled.div`
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InstructionContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const InstructionImage = styled.img`
  width: 250px;
`;

const NextButton = styled.button`
  width: 390px;
  height: 50px;
  max-width: 80%;
  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: #f9f9f9;
  padding: 0px 5px;
  margin-bottom: 1rem;
  border-radius: 30px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media ${media.mobile} {
    width: 96%;
    font-size: 0.9rem;
  }
`;

const Instruction = styled.div`
  border: 1px solid #f7ac3d;
  cursor: pointer;
  border-radius: 4px;
  background-color: #f9f9f9;
  width: 300px;
  max-width: 90%;
  @media ${media.mobile} {
    margin-top: 9px;
  }
  &:hover {
    scale: calc(1.01);
    border-width: 2px;
  }
`;

const Title = styled.span`
  color: black;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;

  @media ${media.mobile} {
    font-size: 1.5em;
    margin-top: 5px;
  }
`;

const Subtitle = styled.h1`
  font-weight: bold;
  font-size: 1.7rem;
  user-select: none;

  @media ${media.mobile} {
    font-size: 1.4em;
    margin-top: 5px;
  }
`;
