import React, { useCallback } from 'react';
import { styled } from 'styles';
import { StudyTrackCard } from 'modules/main/components/themes-page/study-track';
import { useUniversityContext } from 'providers/UniversityProvider';
import { DisciplineCard } from '../../themes-page/discipline';
import { StudyPlanCard } from 'modules/main/components/themes-page/study-plan';
import SubjectGroupsContainer from '../../themes-page/theme/SubjectGroupsContainer';
import BuildStudyPlan from '../../themes-page/guide/BuildStudyPlan';
import OtherSubjectsButton from '../../themes-page/buttons/OtherSubjectsButton';
import { useUserContext } from 'providers/UserProvider';
import SolveQuestionsSection from '../solve-questions/SolveQuestionsSection';
import MyListsSection from '../my-lists/MyListsSection';

export default function NewThemeAccordeon({
  theme,
  disciplines,
  studyPlans,
  isPrivateTheme = false,
  lists,
  onListsMutation,
}) {
  const { user } = useUserContext();
  const { university, universityPronoumn } = useUniversityContext();

  if (!isPrivateTheme)
    studyPlans =
      studyPlans?.filter(sp => parseInt(sp.themeId) === theme.id) || [];

  const disciplineTitle = useCallback(() => {
    if (isPrivateTheme)
      return `${theme.name} e Matemática Básica ao mesmo tempo`;

    if (
      university &&
      university.acronym &&
      university.acronym != 'Selecione a universidade'
    )
      return `${theme.name} ${universityPronoumn} ${university.acronym}`;

    return `${theme.name} da sua faculdade`;
  }, [isPrivateTheme, university, theme, universityPronoumn]);

  return (
    <AccordeonContent>
      {user && (
        <>
          <>
            <h1>Encontre qualquer questão resolvida passo a passo</h1>
            <SolveQuestionsSection onListUpload={onListsMutation} />
            <MyListsSection
              theme={theme}
              lists={lists}
              onListsMutation={onListsMutation}
            />
          </>
          <h1 className="mb-3.5">{disciplineTitle()}</h1>
          <ContainerDisciplines>
            {isPrivateTheme ? (
              <>
                {theme.studyTracks?.map(d => (
                  <StudyTrackCard theme={theme} studyTrack={d} key={d.id} />
                ))}
              </>
            ) : (
              <>
                {disciplines?.map(d => (
                  <DisciplineCard theme={theme} discipline={d} key={d.id} />
                ))}
              </>
            )}
            {studyPlans?.map(
              sp =>
                parseInt(sp.themeId) === theme.id && (
                  <StudyPlanCard
                    theme={theme}
                    studyPlan={sp}
                    key={sp.id}
                    privateThemeColor={isPrivateTheme}
                  />
                )
            )}
            <BuildStudyPlan theme={theme} privateThemeColor={isPrivateTheme} />
          </ContainerDisciplines>
        </>
      )}

      {isPrivateTheme ? (
        <OtherSubjectsButton theme={theme} />
      ) : (
        <SubjectGroupsContainer theme={theme} />
      )}
    </AccordeonContent>
  );
}

const AccordeonContent = styled.div`
  background-color: #f3f3f3;
  padding-bottom: 8px;

  h1 {
    padding-top: 25px;
    padding-left: 20px;
    font-size: 1.2em;
    max-width: 90%;
    font-weight: bold;
    color: #888;
    text-align: left !important;
  }
`;

const ContainerDisciplines = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-left: 25px;

  ::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
