import React, { createContext, useContext, useMemo } from 'react';

const ChatContext = createContext({});

export function ChatProvider({ children, currentChat, stepIndex }) {
  const value = useMemo(
    () => ({
      chatId: currentChat?.id,
      stepIndex,
      messages: currentChat?.messages || [],
    }),
    [currentChat, stepIndex]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}

export function useChat() {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }

  return context;
}
