import React, { useMemo } from 'react';
import { styled } from 'styles';

export default function TextShimmer({
  children,
  as = 'p',
  className,
  duration = 2,
  spread = 2,
  textColor = '#71717a',
  gradientColor = '#fff',
}) {
  const dynamicSpread = useMemo(() => {
    return children.length * spread;
  }, [children, spread]);

  return (
    <ShimmerText
      as={as}
      className={className}
      duration={duration}
      spread={dynamicSpread}
      textColor={textColor}
      gradientColor={gradientColor}
    >
      {children}
    </ShimmerText>
  );
}

const ShimmerText = styled.p`
  position: relative;
  display: inline-block;
  background-size: 250% 100%, auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  --base-color: ${props => props.textColor};
  --base-gradient-color: ${props => props.gradientColor};
  --spread: ${props => props.spread}px;
  --bg: linear-gradient(
    90deg,
    #0000 calc(50% - var(--spread)),
    var(--base-gradient-color),
    #0000 calc(50% + var(--spread))
  );
  background-repeat: no-repeat, padding-box;
  background-image: var(--bg),
    linear-gradient(var(--base-color), var(--base-color));
  animation: shimmerAnimation ${props => props.duration}s linear infinite;

  @keyframes shimmerAnimation {
    0% {
      background-position: 100% center;
    }
    100% {
      background-position: 0% center;
    }
  }
`;
