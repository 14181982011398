import React from 'react';

import dexterAiImg from 'images/mvp/dexter-ai.png';

export function DexterAiProfile() {
  return (
    <div className="w-12 h-12 flex flex-col items-center mr-4 mt-2">
      <img src={dexterAiImg} className="w-12 h-12" />
      <p className="text-xs font-normal text-black mt-1 text-center">
        Dexter IA
      </p>
    </div>
  );
}
