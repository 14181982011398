import { useCallback } from 'react';
import { eligibleAiThemes } from '../utils';

export function useSolveQuestionWithAi() {
  const isListEligible = useCallback(list => {
    const isListFromMKT = list.status === 'incomplete';
    if (isListFromMKT) return true;

    if (list.themeId) {
      return eligibleAiThemes.includes(list.themeId);
    }

    if (!list.discipline) return false;
    return eligibleAiThemes.includes(list.discipline.themeId);
  }, []);

  const isQuestionEligible = useCallback(question => {
    if (question.images.length > 0) return false;
    if (question.status === 'found' && question.exerciseType !== 'MvpExercise')
      return false;

    return true;
  }, []);

  const isAiEligible = useCallback(
    (list, question) => isListEligible(list) && isQuestionEligible(question),
    [isListEligible, isQuestionEligible]
  );

  return { isListEligible, isQuestionEligible, isAiEligible };
}
