import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { ArrowBack } from '@material-ui/icons';
import { Calendar } from 'react-multi-date-picker';
import { WEEK_DAYS, MONTHS } from 'modules/learning-path/utils/calendar';
import { useLearningPathTracker } from 'modules/learning-path/hooks';

const ExamDateStep = ({
  goBack,
  goFoward,
  examDate,
  subjectName,
  themeName,
}) => {
  const { trackExamDateDialogView } = useLearningPathTracker();

  useEffect(() => {
    trackExamDateDialogView({
      Matéria: themeName,
      Assunto: subjectName,
    });
  }, [subjectName, themeName, trackExamDateDialogView]);

  const onDateSelect = useCallback(
    date => {
      goFoward(date.format());
    },
    [goFoward]
  );

  return (
    <Content onClick={e => e.stopPropagation()}>
      <Header>
        <ArrowBackIcon onClick={goBack} />
        <StepIndicator>Passo 2 de 3</StepIndicator>
      </Header>
      <Title>Qual é a data da sua prova?</Title>
      <Calendar
        value={examDate}
        onChange={selectedDate => onDateSelect(selectedDate)}
        showOtherDays={true}
        weekDays={WEEK_DAYS}
        months={MONTHS}
        highlightToday={false}
      />
    </Content>
  );
};

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Content = styled.div`
  width: 95%;
  max-width: 700px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #00000040;
  padding: 40px 20px 75px 20px;
`;
const StepIndicator = styled.div`
  color: #888;
  font-size: 20px;
`;

const ArrowBackIcon = styled(ArrowBack)`
  width: 20px !important;
  position: absolute;
  left: 0;
  cursor: pointer;
  height: 20px !important;
`;

const Title = styled.p`
  font-size: 30px;
  margin: 35px 0;
  font-weight: 700;
  color: #f7ac3b;
`;

export default ExamDateStep;
