import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { media, styled } from 'styles';
import {
  QuestionWithResponse,
  PageSkeleton,
  SearchLogs,
  CheckSearchResult,
  SearchResult,
  QuestionNav,
} from '../components/ListWithStatementQuestion';
import { QuestionTopBar, QuestionContent } from '../components/shared';
import { MathJaxWithLatexProvider } from 'providers';
import { useContentQuery } from 'modules/shared/hooks/requests';
import {
  useQuestions,
  useRenderListWithStatementQuestionPageDialog,
} from '../hooks';
import ListWithStatementQuestionAccessProvider, {
  useListWithStatementQuestionAccess,
} from '../providers/ListWithStatementQuestionAccessProvider';

export function ListWithStatementQuestionPage() {
  return (
    <ListWithStatementQuestionAccessProvider>
      <MathJaxWithLatexProvider>
        <Page />
      </MathJaxWithLatexProvider>
    </ListWithStatementQuestionAccessProvider>
  );
}

function Page() {
  const { listId, questionId } = useParams();
  const { getNextExercise, getPreviousExercise } = useQuestions();
  const { hasAccessToQuestion } = useListWithStatementQuestionAccess();
  const [checkSearchResult, setCheckSearchResult] = useState(false);

  const { data: question, mutate: refetchQuestion } = useContentQuery(
    `/list-with-statements/questions/${questionId}`
  );
  const { data: list, mutate: refetchList } = useContentQuery(
    `/list-with-statements/${listId}`
  );

  const [showFoundQuestionMessage, setShowFoundQuestionMessage] = useState(
    false
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [question]);

  const onCheckResult = useCallback(
    found => {
      refetchQuestion();
      refetchList();
      setCheckSearchResult(false);

      if (found) setShowFoundQuestionMessage(true);
    },
    [refetchQuestion, refetchList]
  );

  const showQuestionResponse = useCallback(() => {
    return question.exercise && !checkSearchResult;
  }, [question, checkSearchResult]);

  const showSearchLog = useCallback(() => {
    return !question.exercise && !checkSearchResult;
  }, [question, checkSearchResult]);

  const showCheckSearchResult = useCallback(() => {
    return checkSearchResult;
  }, [checkSearchResult]);

  const nexQuestion = useMemo(() => {
    if (!question || !list) return;
    return getNextExercise(list, question.id);
  }, [getNextExercise, list, question]);

  const prevQuestion = useMemo(() => {
    if (!question || !list) return;
    return getPreviousExercise(list, question.id);
  }, [getPreviousExercise, list, question]);

  useRenderListWithStatementQuestionPageDialog({
    hasAccess: hasAccessToQuestion(),
    exerciseType: question?.exerciseType,
  });

  if (!question) return <PageSkeleton />;

  return (
    <Container>
      <QuestionTopBar
        listId={listId}
        topic={question.exercise?.topic}
        style={{ marginTop: '20px' }}
      />
      <QuestionContent>
        {showFoundQuestionMessage && (
          <ShowFoundQuestionMessage>
            Uhuuul! 🎉 Agora é só partir pra resolução!
          </ShowFoundQuestionMessage>
        )}
        {showCheckSearchResult() && (
          <>
            <CheckSearchResult
              question={question}
              onCheckResult={onCheckResult}
            />
            <SearchResult question={question} />
          </>
        )}

        {showQuestionResponse() && (
          <QuestionWithResponse
            question={question}
            setCheckSearchResult={setCheckSearchResult}
            list={list}
          />
        )}

        {showSearchLog() && (
          <SearchLogs question={question} onCheckResult={onCheckResult} />
        )}
      </QuestionContent>
      <QuestionNav
        prevQuestion={prevQuestion}
        nextQuestion={nexQuestion}
        listId={listId}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 1080px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media ${media.mobile} {
    margin-top: 80px;
  }
`;

const ShowFoundQuestionMessage = styled.h1`
  margin: 25px auto;
  text-align: center;
  color: #212121;
  font-size: 1.8em;
`;
