import React from 'react';
import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';

import { ExerciseFeedback } from '../../../shared';
import { Answer } from './Answer';
import { Step } from './Step';
import {
  ChatLoaderProvider,
  ChatTrackEventsProvider,
  ChatFeedbackProvider,
} from 'modules/mvp/contexts/ListWithStatementQuestionPage';

export function SolutionSection({ question, showResolution, list }) {
  return (
    <div className={`max-w-full ${showResolution ? 'flex' : 'hidden'}`}>
      <ChatLoaderProvider>
        <ChatTrackEventsProvider
          location="Lista de Enunciado"
          exercise={question.exercise}
          numberOfSteps={question.exercise.lightSolution.length}
          openShowResolution={showResolution}
          anotherDefaultProperties={{
            'Id da Questão': question.id,
            'Id da Lista': question.listId,
            'Id da Matéria no Upload': list?.themeId,
          }}
        >
          <ChatFeedbackProvider>
            <div className="flex flex-col max-w-full text-lg">
              {question.exercise.lightSolution.map((solution, index) => (
                <div key={index} className="mb-8">
                  <Step index={index} />
                  <div className="text-gray-600">
                    <MathjaxContentWithLatex text={solution} marginTop={5} />
                  </div>
                </div>
              ))}

              {showResolution && (
                <div className="my-2">
                  <ExerciseFeedback
                    question={question}
                    origin="fim_da_resolucao"
                    style={{
                      marginLeft: '0px',
                    }}
                  />
                </div>
              )}

              {showResolution && (
                <Answer answer={question.exercise.lightAnswer} />
              )}
            </div>
          </ChatFeedbackProvider>
        </ChatTrackEventsProvider>
      </ChatLoaderProvider>
    </div>
  );
}
