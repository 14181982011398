import React, { useEffect } from 'react';
import { styled, media } from 'styles';
import { useParams } from 'react-router-dom';
import { Loading, Error } from 'modules/shared/components/generic';
import { BookHeader, BookEditions } from 'modules/books/components';
import { useTracker, useGTM } from 'modules/shared/hooks';
import { useBookPermission } from 'modules/books/hooks';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import useRenderBookEditionsPageDialog from '../hooks/useRenderBookEditionsPageDialog';
import DesktopBooksBanner from 'modules/banner/book-banner/DesktopBooksBanner';
import MobileBooksBanner from 'modules/banner/book-banner/MobileBooksBanner';
import { useThemesContext } from 'providers/ThemesProvider';

export default function BookEditionsPage() {
  const { bookId } = useParams();
  const { trackEvent } = useTracker();
  const { addVariableToGTMDataLayer } = useGTM();
  const { shouldBooksFirstChaptersBeFree } = useBookPermission();
  const { setCurrentThemeId } = useThemesContext();

  const { data: book, loading, error } = useContentQueryImmutable(
    `/books/${bookId}`
  );

  useEffect(() => {
    if (book) {
      setCurrentThemeId(book?.themeId);

      addVariableToGTMDataLayer({
        contentBookName: book.name,
        contentBookModule: book.volume,
      });

      trackEvent('Solucionário: Visualizou página de edições', {
        Livro: book.name,
        Freemium: shouldBooksFirstChaptersBeFree ? 'sim' : 'não',
        Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      });
    }
  }, [
    book,
    trackEvent,
    addVariableToGTMDataLayer,
    shouldBooksFirstChaptersBeFree,
    setCurrentThemeId,
  ]);

  useRenderBookEditionsPageDialog();

  if (error) return <Error />;
  if (loading) return <Loading />;

  return (
    <>
      {!media.isMobile() && <DesktopBooksBanner />}
      {media.isMobile() && <MobileBooksBanner />}
      <BookHeader />
      <RaisedContent>
        <BookEditions book={book} />
      </RaisedContent>
    </>
  );
}

const RaisedContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -50px;

  @media ${media.mobile} {
    margin-top: 20px;
  }
`;
