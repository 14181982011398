import React from 'react';
import Select, { components } from 'react-select';

export default function ThemeSelector({
  themes,
  selectedTheme,
  onChange,
  className = 'w-full md:w-52',
}) {
  const selectOptions =
    themes?.map(theme => ({
      value: theme.id,
      theme: theme,
    })) || [];

  const selectedOption = selectOptions.find(
    option => option.value === selectedTheme?.id
  );

  const onSelectChange = option => {
    onChange(option.theme);
  };

  return (
    <Select
      className={className}
      value={selectedOption}
      onChange={onSelectChange}
      options={selectOptions}
      styles={customSelectStyles}
      isSearchable={false}
      components={{
        Option: ({ data: { theme }, isFocused, isSelected, ...props }) => (
          <div
            {...props.innerProps}
            className={`flex p-2 items-center cursor-pointer rounded-md m-1 ${
              isFocused ? 'bg-gray-100' : ''
            } ${isSelected ? 'bg-gray-200' : ''}`}
          >
            <div
              className="w-7 h-7 rounded-full mr-2 flex-shrink-0 flex items-center justify-center"
              style={{ backgroundColor: theme.color }}
            >
              <img src={theme.imagePath} alt="" className="w-4 h-4" />
            </div>
            <span className="text-left">{theme.name}</span>
          </div>
        ),
        SingleValue: ({ data: { theme }, ...props }) => (
          <components.SingleValue {...props}>
            <div className="flex items-center overflow-hidden">
              <div
                className="w-7 h-7 rounded-full mr-2 flex-shrink-0 flex-grow-0 flex items-center justify-center overflow-hidden"
                style={{ backgroundColor: theme.color }}
              >
                <img src={theme.imagePath} alt="" className="w-4 h-4" />
              </div>
              <span className="text-left whitespace-nowrap overflow-ellipsis overflow-hidden flex-1 mr-2">
                {theme.name}
              </span>
            </div>
          </components.SingleValue>
        ),
      }}
    />
  );
}

// Custom styles for react-select
const customSelectStyles = {
  menu: provided => ({
    ...provided,
    width: '300px',
  }),
  input: provided => ({
    ...provided,
    display: 'none',
  }),
  control: base => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    minHeight: '2rem',
    paddingTop: 0,
    paddingBottom: 0,
  }),
  singleValue: provided => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 0,
  }),
  valueContainer: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};
