import React from 'react';

import { useChatLoader } from '../../../../../contexts/ListWithStatementQuestionPage';

export function StepTitle({ stepIndex, onInfoBoxToggle }) {
  const { isEligible } = useChatLoader();

  return (
    <div
      className={`flex items-center group mb-2 ${
        stepIndex === 0 ? 'mt-4' : ''
      }`}
    >
      <h1 className="text-orange-400 font-bold text-3xl leading-none">
        Passo {stepIndex + 1}
      </h1>
      {isEligible && (
        <div
          className="ml-3 bg-white text-orange-400 border border-orange-400 border-solid p-2 rounded-full cursor-pointer items-center justify-center select-none flex gap-2"
          onClick={onInfoBoxToggle}
        >
          <IAIcon color="orange" />
          <span className="text-sm">Tirar dúvida deste passo</span>
        </div>
      )}
    </div>
  );
}

function IAIcon({ size = 18, color = 'white', ...props }) {
  return (
    <svg
      stroke="currentColor"
      fill={color}
      strokeWidth="0"
      viewBox="0 0 16 16"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"></path>
    </svg>
  );
}
