import React from 'react';
import { MdError } from 'react-icons/md';
import { UploadStatus } from '../../hooks/useListUpload';
import InProgressStepper from './components/InProgressStepper';

const {
  NOT_STARTED,
  FAILED,
  COMPLETED_WITH_RESULTS,
  COMPLETED_WITHOUT_RESULTS,
} = UploadStatus;

export default function UploadStatusInfo({
  file,
  uploadStatus,
  showResult,
  uploadTheme,
}) {
  if (uploadStatus === NOT_STARTED) {
    return <FileDetails file={file} />;
  } else if (uploadStatus === FAILED) {
    return <ErrorMessage />;
  } else if (showResult) {
    if (uploadStatus === COMPLETED_WITH_RESULTS) {
      return <CompletedWithResultsMessage themeColor={uploadTheme?.color} />;
    } else if (uploadStatus === COMPLETED_WITHOUT_RESULTS) {
      return <CompletedWithoutResultsMessage />;
    }
  }

  return (
    <InProgressStepper
      uploadStatus={uploadStatus}
      themeColor={uploadTheme?.color}
    />
  );
}

const FileDetails = ({ file }) => {
  return (
    <div className="flex-1 text-center md:text-left p-3 md:p-0">
      <p className="text-sm md:text-base font-bold text-gray-500 md:text-gray-800 break-all">
        {file.name}
      </p>
      <p className="text-sm text-gray-500">
        {(file.size / (1024 * 1024)).toFixed(2)} MB
      </p>
    </div>
  );
};

const CompletedWithResultsMessage = ({ themeColor }) => {
  return (
    <div className="flex-1 text-center md:text-left my-3 md:my-0">
      <h3 className="font-bold text-xl" style={{ color: themeColor }}>
        Sua lista está pronta! 🤩
      </h3>
      <p className="text-sm md:text-base text-gray-400 mt-1 leading-4 md:leading-5">
        Acesse agora e veja todas as resoluções passo a passo que encontramos.
      </p>
    </div>
  );
};

const CompletedWithoutResultsMessage = () => {
  return (
    <div className="flex-1 text-center md:text-left my-3 md:my-0">
      <h3 className="font-bold text-center md:text-left text-base md:text-lg text-red-400 md:flex md:items-center">
        <MdError className="mb-2 md:mb-0 md:mr-2" size={24} />
        <br className="md:hidden" />
        Poxa, ainda não conseguimos
        <br className="md:hidden" /> resolver essa lista :(
      </h3>
      <p className="text-sm text-gray-500 mt-3 md:mt-1 leading-4">
        Experimente enviar outra lista ou veja as questões parecidas que
        encontramos.
      </p>
    </div>
  );
};

const ErrorMessage = () => {
  return (
    <div className="flex-1 text-center md:text-left my-3 md:my-0">
      <h3 className="font-bold text-center md:text-left text-base md:text-lg text-red-400 md:flex md:items-center">
        <MdError className="mb-2 md:mb-0 md:mr-2" size={24} />
        <br className="md:hidden" />
        Houve um erro ao processar <br className="md:hidden" /> essa lista :(
      </h3>
      <p className="text-sm text-gray-500 mt-3 md:mt-1 leading-4">
        Tente novamente ou experimente enviar outra lista. Se o problema
        persistir, entre em contato com nosso atendimento.
      </p>
    </div>
  );
};
