import React, { useEffect, useCallback, useMemo } from 'react';
import { media, styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker } from 'modules/shared/hooks';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { useFeatureFlagContext } from 'providers/FeatureFlagProvider';
import useListUploadDialog from 'modules/lists/hooks/useListUploadDialog';

export default function MvpThemesListBanner({ origin, utmContent }) {
  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const { hasFlagNewHome } = useFeatureFlagContext();

  const utms = useMemo(() => {
    return {
      utmSource: 'site_upload',
      utmMedium: 'banner',
      utmContent,
    };
  }, [utmContent]);

  const { triggerListFileInput, ListFileInput } = useListUploadDialog(utms);

  const eventProperties = useMemo(() => {
    return {
      Onde: origin,
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Versão: '2.1',
    };
  }, [origin]);

  const onClickBanner = useCallback(() => {
    trackEvent('MVP Lista: Clicou Banner', eventProperties);

    if (hasFlagNewHome()) {
      triggerListFileInput();
    } else {
      setDialog(<UploadBookListDialog origin={origin} utms={utms} />);
    }
  }, [
    trackEvent,
    eventProperties,
    setDialog,
    origin,
    triggerListFileInput,
    hasFlagNewHome,
    utms,
  ]);

  useEffect(() => {
    trackEvent('MVP Lista: Visualizou Banner', eventProperties);
  }, [trackEvent, eventProperties]);

  return (
    <>
      {media.isDesktop() ? (
        <ContainerBanner onClick={onClickBanner}>
          <ContentBanner>
            <BannerTitle>
              Resolvemos a sua lista de Exercícios
              <br /> em 45 segundos
            </BannerTitle>
            <BannerButton>Quero minha lista resolvida</BannerButton>
          </ContentBanner>
          <RightTriangle />
          <BottomBase />
          <LeftTriangle />
        </ContainerBanner>
      ) : (
        <ContainerMobileBanner onClick={onClickBanner}>
          <h2>Resolvemos a sua lista em 45 segundos!</h2>
          <CTAButtonMobile>Quero a minha lista resolvida</CTAButtonMobile>
        </ContainerMobileBanner>
      )}
      {ListFileInput}
    </>
  );
}

const ContainerBanner = styled.div`
  position: relative;
  width: 780px;
  max-width: 90%;
  height: 110px;
  margin: auto;
  margin-left: calc(50% - 390px - 30px); /* Desloca 30px para a esquerda */

  @media (max-width: 1310px) {
    width: 680px;
    margin-left: calc(50% - 340px - 30px);
  }

  cursor: pointer;

  @media ${media.mobile} {
    display: none;
  }
`;

const ContentBanner = styled.div`
  position: relative;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #06a54f;
  z-index: 2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 10px auto 0 auto;
  border-radius: 6px;
  cursor: pointer;

  @media ${media.mobile} {
    align-items: top;
    height: 50px;
  }
`;

const RightTriangle = styled.div`
  position: absolute;
  top: 0px;
  right: -105px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 110px 0 0 110px;
  border-color: transparent transparent transparent #0f6a39;

  @media ${media.mobile} {
    top: 2px;
    right: -13px;
    border-width: 78px 0 0 14px;
    z-index: 9999;
  }
`;

const BottomBase = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 30px;
  width: 100%;
  background-color: #0f6a39;

  @media ${media.mobile} {
    bottom: 2px;
    z-index: 9999;
    height: 20px;
  }
`;

const LeftTriangle = styled.div`
  position: absolute;
  bottom: 0;
  left: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 0 0 30px;
  border-color: transparent transparent transparent #f9f9f9;

  @media ${media.mobile} {
    left: -8px;
    z-index: 9999;
  }
`;

const BannerTitle = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  color: #fff;
  margin-left: 20px;
  text-align: center;

  @media (max-width: 1310px) {
    font-size: 1.2rem;
  }

  @media ${media.mobile} {
    font-size: 0.6rem;
    line-height: 15px;
    margin-left: 7px;
    flex-grow: 1;
  }
`;

const BannerButton = styled.div`
  width: 350px;
  height: 50px;
  background-color: #ffffff;
  color: #0f6a39;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  font-size: 1.2rem;
  font-weight: bold;

  @media (max-width: 1310px) {
    font-size: 1.1rem;
    width: 280px;
  }

  @media ${media.mobile} {
    width: 120px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;

    font-size: 0.55rem;
  }
`;

const ContainerMobileBanner = styled.div`
  width: 100%;
  height: 60px;
  background: #06a54f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;

  h2 {
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    max-width: 90%;
  }
`;

const CTAButtonMobile = styled.button`
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  outline: 0;
  border: 0;
  text-transform: uppercase;
  color: #0f6a39;
  font-weight: bold;
  font-size: 10px;
  padding: 0 20px;
`;
