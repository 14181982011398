import React, { useCallback, useEffect } from 'react';
import googleOneTap from 'google-one-tap';
import 'modules/shared/utils/protect-agains-posso-ler';
import * as amplitude from '@amplitude/analytics-browser';

import { Loading, Error } from 'modules/shared/components/generic';
import { LoggedRouter, UnloggedRouter } from 'modules/shared/routes';
import { useUserContext } from 'providers/UserProvider';
import {
  useUTM,
  useCookies,
  useToast,
  useUserHistoryTracking,
} from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import { useDoubleAccess, DoubleAccessDialog } from 'modules/auth';
import { useIsOrganicCookie } from 'modules/shared/hooks/useIsOrganicCookie';
import { emitter } from '@marvelapp/react-ab-test';

export default function App() {
  const { user, loading, error } = useUserContext();
  const { monitoreToastInSessionStorage } = useToast();
  const { showUserDoubleAccessDialog } = useDoubleAccess();
  const { setGlobalDialog } = useDialogContext();

  useIsOrganicCookie();
  useUserHistoryTracking();

  useEffect(() => {
    monitoreToastInSessionStorage();
  }, [monitoreToastInSessionStorage]);

  useEffect(() => {
    if (showUserDoubleAccessDialog()) setGlobalDialog(<DoubleAccessDialog />);
  }, [setGlobalDialog, showUserDoubleAccessDialog]);

  const { getUTMsByLocation } = useUTM();
  const [cookies, setCookie] = useCookies([
    'should_use_google_one_tap',
    'mkt_utm_campaign',
    'mkt_utm_medium',
    'mkt_utm_source',
    'mkt_utm_content',
    'mkt_utm_term',
    'seo_last_page',
  ]);

  const shouldUseSeeOneTapLogin = useCallback(() => {
    let should = cookies.should_use_google_one_tap;

    if (should === undefined) {
      should = Math.random() < 0 ? 'true' : 'false';
      setCookie('should_use_google_one_tap', should, {
        domain: process.env.REACT_APP_RESPONDEAI_DOMAIN,
        path: '/',
      });
    }

    return should === 'true';
  }, [cookies, setCookie]);

  useEffect(() => {
    if (user) {
      const style = document.createElement('style');
      style.id = 'google-one-tap-desktop-style';
      style.innerHTML = '#credential_picker_container { display: none; }';
      document.getElementsByTagName('head')[0].appendChild(style);

      const styleMobile = document.createElement('style');
      styleMobile.id = 'google-one-tap-mobile-style';
      styleMobile.innerHTML = '#credential_picker_iframe { display: none; }';
      document.getElementsByTagName('head')[0].appendChild(styleMobile);

      return () => {
        // Cleanup on unmount
        const desktopStyle = document.getElementById(
          'google-one-tap-desktop-style'
        );
        const mobileStyle = document.getElementById(
          'google-one-tap-mobile-style'
        );
        if (desktopStyle) desktopStyle.remove();
        if (mobileStyle) mobileStyle.remove();
      };
    }

    if (!shouldUseSeeOneTapLogin()) return;

    const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    if (!client_id) return;

    const options = {
      client_id,
      cancel_on_tap_outside: false,
      context: 'use',
      ux_mode: 'redirect',

      login_uri: `${process.env.REACT_APP_RESPONDEAI_URL}/users/auth/google_oauth2/callback`,
    };

    googleOneTap(options, async response => {
      response.code = response.credential;
      response.provider = 'google_oauth2';
      response.data = { idToken: response.credential };

      let backendLoginURL = `${process.env.REACT_APP_RESPONDEAI_URL}/sign_in_by_one_tap_token?`;
      backendLoginURL += `provider=google_oauth2&`;
      backendLoginURL += `code=${response.credential}&`;
      backendLoginURL += `id_token=${response.credential}&`;
      backendLoginURL += `redirect_uri=${location.href}`;

      location.href = backendLoginURL;
    });
  }, [user, shouldUseSeeOneTapLogin]);

  useEffect(() => {
    if (!amplitude) return;

    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
      autocapture: {
        pageViews: false,
        formInteractions: false,
      },
    });
  }, []);

  useEffect(() => {
    if (!user) return;

    amplitude.setUserId(user.id);
    startUserVoice(user);
  }, [user]);

  useEffect(() => {
    const utms = getUTMsByLocation(window.location);
    if (!utms) return;

    utms['MKT: UTM Campaign'] &&
      setCookie('mkt_utm_campaign', utms['MKT: UTM Campaign']);
    utms['MKT: UTM Medium'] &&
      setCookie('mkt_utm_medium', utms['MKT: UTM Medium']);
    utms['MKT: UTM Source'] &&
      setCookie('mkt_utm_source', utms['MKT: UTM Source']);
    utms['MKT: UTM Content'] &&
      setCookie('mkt_utm_content', utms['MKT: UTM Content']);
    utms['MKT: UTM Term'] && setCookie('mkt_utm_term', utms['MKT: UTM Term']);
  }, [getUTMsByLocation, setCookie]);

  emitter.defineVariants('seo_question_trial', ['no_trial', 'trial']);

  if (error) return <Error />;
  if (loading) return <Loading />;

  return user ? <LoggedRouter /> : <UnloggedRouter />;
}

const startUserVoice = user => {
  if (!window.UserVoice) return;

  window.UserVoice.push([
    'identify',
    {
      email: user.email,
      name: `${user.name} (${user?.university?.acronym || 'Sem Universidade'})`,
      first_name: `${user.name.split(' ')[0]}`,
      id: user.id,
      type: `${user.hasAccess ? 'Assinante' : 'Não Assinante'}`,
    },
  ]);

  window.UserVoice.push(['autoprompt', {}]);
};
