import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useContentQuery } from 'modules/shared/hooks/requests';
import { Header } from '../components/SearchedExercisePage/Header';
import { ExerciseSkeleton } from '../components/SearchedExercisePage/ExerciseSkeleton';
import { Exercise } from '../components/SearchedExercisePage/Exercise';
import { Error } from 'modules/shared/components/generic';
import MathpixLoader from 'providers/MathpixLoader';
import { useRenderSearchedExercisePageDialog } from '../hooks/useRenderSearchedExercisePageDialog';
import { useUserContext } from 'providers/UserProvider';
import { useTrackerSearchedExercisePage } from '../hooks/useTrackerSearchedExercisePage';

export function SearchedExercisePage() {
  const { exerciseId } = useParams();
  const { data: exercise, loading, error } = useContentQuery(
    `/ai/searched-exercises/${exerciseId}`
  );
  const { user } = useUserContext();
  const { trackViewPage } = useTrackerSearchedExercisePage();

  const hasAccess = useMemo(() => {
    return user && user.hasAccess;
  }, [user]);

  useEffect(() => {
    if (!user) return;

    trackViewPage(hasAccess);
  }, [trackViewPage, hasAccess, user]);

  useRenderSearchedExercisePageDialog();

  if (error) return <Error />;
  return (
    <MathpixLoader>
      <Header />

      {loading && <ExerciseSkeleton height={500} />}
      {exercise && <Exercise exercise={exercise} hasAccess={hasAccess} />}
    </MathpixLoader>
  );
}
