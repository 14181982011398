import React, { useMemo } from 'react';
import { media, styled } from 'styles';
import Stepper from '@keyvaluesystems/react-stepper';
import { UploadStatus } from '../../../hooks/useListUpload';
import TextShimmer from './TextShimmer';

const {
  UPLOADING,
  SPLITTING,
  SEARCHING,
  COMPLETED_WITH_RESULTS,
  COMPLETED_WITHOUT_RESULTS,
} = UploadStatus;

const stepsOrder = [UPLOADING, SPLITTING, SEARCHING];

const stepsData = {
  [UPLOADING]: {
    label: 'Enviando arquivo',
    description: null,
  },
  [SPLITTING]: {
    label: 'Separando questões da lista',
    description: null,
  },
  [SEARCHING]: {
    label: 'Buscando resoluções passo a passo para cada questão',
    description: 'Esta etapa leva, em média, 40 segundos',
  },
};

export default function InProgressStepper({ uploadStatus, themeColor }) {
  const isCompleted =
    uploadStatus === COMPLETED_WITH_RESULTS ||
    uploadStatus === COMPLETED_WITHOUT_RESULTS;

  const currentStep = isCompleted
    ? stepsOrder.length // sets a bigger number to show all steps as completed
    : stepsOrder.findIndex(step => step === uploadStatus);

  const steps = useMemo(() => {
    return getSteps(uploadStatus, themeColor, currentStep);
  }, [uploadStatus, themeColor, currentStep]);

  const stepperStyles = useMemo(() => {
    return getStepperStyles(themeColor);
  }, [themeColor]);

  return (
    <StepperContainer>
      <Stepper
        steps={steps}
        currentStepIndex={currentStep}
        styles={stepperStyles}
      />
    </StepperContainer>
  );
}

const getSteps = (uploadStatus, themeColor, currentStepIndex) => {
  return stepsOrder.map(step => {
    const isActive = uploadStatus === step;
    const { label, description } = stepsData[step];

    const stepCompleted = currentStepIndex > stepsOrder.indexOf(step);

    const stepLabel = isActive ? (
      <ActiveLabelTitle
        label={label}
        description={description}
        themeColor={themeColor}
      />
    ) : (
      <InactiveLabelTitle label={label} />
    );

    return {
      stepLabel,
      completed: stepCompleted,
    };
  });
};

const ActiveLabelTitle = ({ label, description, themeColor }) => {
  return (
    <div className="flex flex-col text-sm md:text-base">
      <TextShimmer textColor={themeColor}>{label}</TextShimmer>
      {description && (
        <span className="text-xs md:text-sm text-gray-500 font-light text-left italic md:block hidden">
          {description}
        </span>
      )}
    </div>
  );
};

const InactiveLabelTitle = ({ label }) => {
  return <span className="text-sm md:text-base">{label}</span>;
};

const StepperContainer = styled.div`
  margin-top: -20px;
  margin-bottom: -20px;

  @media ${media.mobile} {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .verticalStepperWrapper {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .verticalStepper {
    padding: 0;
  }

  .node > div {
    margin: 10px 0;

    @media ${media.mobile} {
      width: 20px;
      height: 20px;
    }
  }

  .descriptionContainer {
    display: none !important;
  }
`;

const getStepperStyles = themeColor => {
  const themeColorBackgroundStyle = () => ({
    backgroundColor: themeColor,
  });

  return {
    LabelTitle: () => ({
      color: '#bbb',
      textAlign: 'left',
      fontSize: '16px',
      paddingLeft: '7px',
      fontWeight: 'normal',
    }),
    ActiveLabelTitle: () => ({
      color: themeColor,
      fontWeight: 'bold',
    }),
    CompletedLabelTitle: () => ({
      color: themeColor,
      fontWeight: 'bold',
    }),
    Node: () => ({
      backgroundColor: '#bbb',
    }),
    LineSeparator: themeColorBackgroundStyle,
    CompletedNode: themeColorBackgroundStyle,
    ActiveNode: themeColorBackgroundStyle,
  };
};
