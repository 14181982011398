import React, { useCallback, useState } from 'react';
import { SubjectStep, DurationStep, ExamDateStep } from './steps';
import { useDialogContext } from 'providers/DialogProvider';
import { useLearningPath, useLearningPathTracker } from '../hooks';
import {
  useContentRestRequest,
  useRAPostRequest,
} from 'modules/shared/hooks/requests';
import styled from 'styled-components';

const DIALOG_NAMES = [
  'Tela de Assunto',
  'Tela de Data de Provas',
  'Tela de Duração',
];

export const LearningPathCreateFromDialog = () => {
  const { learningPaths } = useLearningPath();
  const { sendPostRequest } = useContentRestRequest();
  const GiveTrial = useRAPostRequest('/ganhar-trial-generico');
  const {
    trackSubjectClick,
    trackExamDateClick,
    trackDurationClick,
    trackCloseForm,
    trackErrorOnLearningPathCriation,
  } = useLearningPathTracker();

  const [step, setStep] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedSubjectName, setSelectedSubjectName] = useState();
  const [selectedSubjectTheme, setSelectedSubjectTheme] = useState();
  const [examDate, setExamDate] = useState();
  const [, setSelectedDuration] = useState();

  const { unsetDialog } = useDialogContext();

  const selectSubject = useCallback(
    (subjectName, themeName) => {
      trackSubjectClick({
        Assunto: subjectName,
        Matéria: themeName,
      });
      setSelectedSubjectName(subjectName);
      setSelectedSubjectTheme(themeName);
      setStep(2);
    },
    [trackSubjectClick]
  );

  const selectExamDate = useCallback(
    examDate => {
      trackExamDateClick({
        Matéria: selectedSubjectTheme,
        Assunto: selectedSubjectName,
        'Data da Prova': examDate,
      });
      setExamDate(examDate);
      setStep(3);
    },
    [selectedSubjectName, selectedSubjectTheme, trackExamDateClick]
  );

  const submit = useCallback(
    async duration => {
      setIsLoading(true);
      trackDurationClick({
        Assunto: selectedSubjectName,
        Duração: duration,
        Matéria: selectedSubjectTheme,
      });
      setSelectedDuration(duration);

      try {
        await sendPostRequest('/learning-path/create-user-learning-path', {
          name: selectedSubjectName,
          days: duration,
        });

        await GiveTrial({ duration: duration, reason: selectedSubjectName });
      } catch (e) {
        console.log(`erro ${e}`);
        trackErrorOnLearningPathCriation({ error: e });
        alert(
          'erro ao criar a trilha de estudos, por favor entre em contato com o suporte'
        );
      }
      unsetDialog();
      window.location.href = `${process.env.REACT_APP_REACT_URL}/mvp/trilha-de-estudos`;

      // mandar info para o RA manda para a sales
    },
    [
      GiveTrial,
      selectedSubjectName,
      selectedSubjectTheme,
      sendPostRequest,
      trackDurationClick,
      trackErrorOnLearningPathCriation,
      unsetDialog,
    ]
  );

  const goBack = useCallback(() => {
    if (step <= 1) return;
    setStep(step - 1);
  }, [step]);

  const handleCloseDialog = useCallback(() => {
    unsetDialog();
    trackCloseForm({ 'Última Dialog': DIALOG_NAMES[step - 1] });
  }, [step, trackCloseForm, unsetDialog]);

  return (
    <Container onClick={handleCloseDialog}>
      {step === 1 && (
        <SubjectStep learningPaths={learningPaths} goFoward={selectSubject} />
      )}
      {step === 2 && (
        <ExamDateStep
          goBack={goBack}
          goFoward={selectExamDate}
          examDate={examDate}
          subjectName={selectedSubjectName}
          themeName={selectedSubjectTheme}
        />
      )}
      {step === 3 && (
        <DurationStep
          subjectName={selectedSubjectName}
          themeName={selectedSubjectTheme}
          goBack={goBack}
          submit={submit}
          isLoading={isLoading}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
