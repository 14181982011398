import React, { useState, useEffect, useCallback } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { styled, media, colors } from 'styles';
import CreditCardMask from 'modules/shared/components/inputs/CreditCardMask';
import CreditCardValidateDateMask from 'modules/shared/components/inputs/CreditCardValidateDateMask';
import { useCheckoutLight } from 'modules/campaigns/checkout-light/hooks';
import { useTracker, useUTM } from 'modules/shared/hooks';
import MundiSecurityImage from 'images/generic/mundipagg-secutiry.png';

export default function CreditCardForm({
  afterCompleteCreditCardData,
  backFromCreditCardForm,
  loading,
  plan,
}) {
  const [cardFocus, setCardFocus] = useState('');
  const [creditCardData, setCreditCardData] = useState(
    INITIAL_CREDIT_CARD_DATA
  );
  const [installments, setInstallments] = useState(1);
  const [creditCardDataError, setCreditCardDataError] = useState(
    INITIAL_CREDIT_CARD_DATA
  );

  const [alreadyReceiveFormError, setAlreadyReceiveFormError] = useState(false);

  const {
    getCreditCardErrors,
    getInstallmentsMonthlyPrice,
  } = useCheckoutLight();
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  useEffect(() => {
    if (!alreadyReceiveFormError) return;

    const errors = getCreditCardErrors(creditCardData);
    setCreditCardDataError(errors);
  }, [creditCardData, alreadyReceiveFormError, getCreditCardErrors]);

  useEffect(() => {
    trackEvent('Checkout Light: Form Cartão Visualizou', {
      ...getUTMsByLocation(window.location),
      Plano: plan.description,
      Preco: plan.pricing.price,
    });
  }, [getUTMsByLocation, plan, trackEvent]);

  const onInputChange = useCallback(
    (key, value) => {
      setCreditCardData({ ...creditCardData, [key]: value });
    },
    [setCreditCardData, creditCardData]
  );

  const trackFormError = useCallback(
    errors => {
      const keys = Object.keys(errors);
      const eventProperties = { ...getUTMsByLocation(window.location) };
      for (const key of keys) {
        if (!errors[key]) continue;

        eventProperties[key] = errors[key];
      }
      trackEvent('Checkout Light: Form Cartão Erro', eventProperties);
    },
    [getUTMsByLocation, trackEvent]
  );

  const handleContinuePress = useCallback(() => {
    if (loading) return;

    const errors = getCreditCardErrors(creditCardData);
    if (errors.number || errors.holderName || errors.expiry || errors.cvv) {
      setCreditCardDataError(errors);
      trackFormError(errors);
      setAlreadyReceiveFormError(true);
      return;
    }

    afterCompleteCreditCardData(creditCardData, installments);
  }, [
    loading,
    getCreditCardErrors,
    creditCardData,
    afterCompleteCreditCardData,
    installments,
    trackFormError,
  ]);

  const handleInputFocus = useCallback(
    name => {
      setCardFocus(name);
    },
    [setCardFocus]
  );

  const handleBackPress = useCallback(() => {
    backFromCreditCardForm();
  }, [backFromCreditCardForm]);

  const getAvailableInstallmentsOptions = useCallback(() => {
    const options = [];
    for (let index = 0; index < plan.months; index++) {
      const installments = index + 1;
      const monthlyPrice = getInstallmentsMonthlyPrice(
        plan.pricing,
        installments
      );
      const text = `${installments} x R$ ${monthlyPrice} / mês`;
      options.push({ text, installments });
    }

    return options;
  }, [plan, getInstallmentsMonthlyPrice]);

  return (
    <Container>
      <SecurityImage src={MundiSecurityImage} />
      <Title>Informe os dados de pagamento</Title>

      {media.isDesktop() && (
        <Cards
          cvc={creditCardData.cvv}
          expiry={creditCardData.expiry}
          focused={cardFocus}
          name={creditCardData.holderName}
          number={creditCardData.number}
          placeholders={{ name: 'NOME NO CARTAO' }}
        />
      )}

      <CustomTextField
        label="Número do Cartão"
        InputProps={{
          inputComponent: CreditCardMask,
        }}
        value={creditCardData.number}
        onChange={e => onInputChange('number', e.target.value)}
        helperText={creditCardDataError.number}
        error={!!creditCardDataError.number}
        onFocus={() => handleInputFocus('number')}
      />
      <CustomTextField
        label="Nome no Cartão"
        value={creditCardData.holderName}
        onChange={e => {
          const newName = e.target.value;
          if (newName.length > 64) return;
          if (!newName.match(/^[a-zA-Z ]*$/)) return;

          onInputChange('holderName', e.target.value);
        }}
        helperText={creditCardDataError.holderName}
        error={!!creditCardDataError.holderName}
        onFocus={() => handleInputFocus('name')}
      />
      <CustomTextField
        label="Validade"
        InputProps={{
          inputComponent: CreditCardValidateDateMask,
        }}
        value={creditCardData.expiry}
        onChange={e => onInputChange('expiry', e.target.value)}
        helperText={creditCardDataError.expiry}
        error={!!creditCardDataError.expiry}
        onFocus={() => handleInputFocus('expiry')}
      />
      <CustomTextField
        label="Código de Segurança do Cartão"
        value={creditCardData.cvv}
        onChange={e => {
          const newCvv = e.target.value;
          const newCvvOnlyNumbers = newCvv.replace(/[^\d.-]/g, '');
          if (newCvvOnlyNumbers.length > 4) return;

          onInputChange('cvv', newCvvOnlyNumbers);
        }}
        helperText={creditCardDataError.cvv}
        error={!!creditCardDataError.cvv}
        onFocus={() => handleInputFocus('cvc')}
      />
      {getAvailableInstallmentsOptions().length > 1 && (
        <CustomTextField
          label="Parcelas"
          value={installments}
          onChange={e => setInstallments(e.target.value)}
          className="select"
          select
        >
          {getAvailableInstallmentsOptions().map(option => (
            <MenuItem key={option.installments} value={option.installments}>
              {option.text}
            </MenuItem>
          ))}
        </CustomTextField>
      )}

      <ButtonsContainer>
        <Button className="highlight" onClick={handleContinuePress}>
          {loading ? 'Carregando...' : 'Finalizar compra'}
        </Button>
        <Button className="light" onClick={handleBackPress}>
          Voltar
        </Button>
      </ButtonsContainer>
    </Container>
  );
}

const INITIAL_CREDIT_CARD_DATA = {
  number: '',
  holderName: '',
  expiry: '',
  cvv: '',
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 1.2em;
  font-weight: bold;
  color: #000;
  margin: 10px;
`;

const CustomTextField = styled(TextField)`
  margin: 5px 0 !important;
  width: 100%;
  font-weight: bold;

  &.select {
    .MuiInput-formControl {
      text-align: left;
    }
  }
`;

const ButtonsContainer = styled.div`
  width: 400px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  border: 2px solid #000;
  font-weight: bold;
  font-size: 1.5em;
  margin: 5px 0;
  padding: 10px 0;
  border-radius: 5px;

  &.highlight {
    background-color: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
    color: #fff;
  }

  &.light {
    color: #a2a2a2;
    border-color: #a2a2a2;
  }

  @media ${media.mobile} {
    font-size: 1.4em;
  }
`;

const SecurityImage = styled.img`
  width: 200px;
  max-width: 90%;
`;
