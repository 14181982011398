import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ArrowBack } from '@material-ui/icons';
import { useLearningPathTracker } from 'modules/learning-path/hooks';
import { ClipLoader } from 'modules/shared/components/generic/loaders';

const DurationStep = ({
  goBack,
  submit,
  subjectName,
  themeName,
  isLoading,
}) => {
  const { trackDurationDialogView } = useLearningPathTracker();

  useEffect(() => {
    trackDurationDialogView({
      Assunto: subjectName,
      Matéria: themeName,
    });
  }, [subjectName, themeName, trackDurationDialogView]);

  if (isLoading) {
    return (
      <Content onClick={e => e.stopPropagation()}>
        <LoadingTitle>
          Estamos criando a sua trilha de estudos, por favor aguarde
        </LoadingTitle>
        <ClipLoader color="#f7ac3b" />
      </Content>
    );
  }

  return (
    <Content onClick={e => e.stopPropagation()}>
      <Header>
        <ArrowBackIcon onClick={goBack} />
        <StepIndicator>Passo 3 de 3</StepIndicator>
      </Header>
      <Title>Quanto tempo você tem para estudar?</Title>
      <DaysButton onClick={() => submit(3)}>3 dias</DaysButton>
      <DaysButton onClick={() => submit(5)}>5 dias ou mais</DaysButton>
      <DaysButton onClick={() => submit(1)}>já tô na véspera!</DaysButton>
    </Content>
  );
};

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Content = styled.div`
  width: 95%;
  max-width: 700px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #00000040;
  padding: 40px 20px 75px 20px;
`;

const ArrowBackIcon = styled(ArrowBack)`
  width: 20px !important;
  position: absolute;
  left: 0;
  cursor: pointer;
  height: 20px !important;
`;

const Title = styled.p`
  font-size: 30px;
  margin: 35px 0;
  font-weight: 700;
  color: #f7ac3b;
`;

const StepIndicator = styled.div`
  color: #888;
  font-size: 20px;
`;

const DaysButton = styled.div`
  width: 320px;
  height: 50px;
  max-width: 80%;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px #00000040;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  cursor: pointer;
`;

const LoadingTitle = styled.p`
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #f7ac3b;
`;

export default DurationStep;
