import React, { createContext, useState, useContext, useMemo } from 'react';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { Loading } from 'modules/shared/components/generic';

export const ThemesContext = createContext(null);

const ThemesProvider = props => {
  const { children } = props;

  const [currentThemeId, setCurrentThemeId] = useState(null);

  const {
    data: themes,
    loading: themesLoading,
    error: themesError,
  } = useContentQueryImmutable(`/themes/with-subjects`);

  const currentTheme = useMemo(() => {
    return themes?.find(theme => theme.id === currentThemeId) || null;
  }, [currentThemeId, themes]);

  return (
    <ThemesContext.Provider
      value={{
        themes,
        themesLoading,
        themesError,
        currentTheme,
        currentThemeId,
        setCurrentThemeId,
      }}
    >
      {themesLoading ? <Loading /> : <>{children}</>}
    </ThemesContext.Provider>
  );
};

export const useThemesContext = () => {
  const context = useContext(ThemesContext);

  if (!context) {
    throw new Error('useThemesContext must be used within a ThemesProvider');
  }

  return context;
};

export default ThemesProvider;
