import React, { useMemo, useState, useRef, useEffect } from 'react';

import { MessageInput } from './MessageInput';
import { UserMessage } from './UserMessage';
import { AssistantMessage } from './AssistantMessage';
import { LoadingAssistantMessage } from './LoadingAssistantMessage';
import {
  useChatLoader,
  useChatTrackEvents,
} from 'modules/mvp/contexts/ListWithStatementQuestionPage';
import { ChatProvider } from 'modules/mvp/contexts/ListWithStatementQuestionPage';

export function ChatBox({ index }) {
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [localMessages, setLocalMessages] = useState([]);
  const { isLoading, chats, postMessage, invalidate } = useChatLoader();
  const {
    trackFeatureSendMessage,
    trackFeatureMessageReceived,
  } = useChatTrackEvents();
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  const currentChat = useMemo(
    () => chats?.find(chat => chat.stepIndex === index),
    [chats, index]
  );

  const userMessagesCount = useMemo(
    () =>
      currentChat?.messages?.filter(message => message.role === 'user')
        ?.length || 0,
    [currentChat?.messages]
  );

  const isMaxMessagesReached = userMessagesCount >= 10;

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [currentChat?.messages, localMessages, isSending]);

  const handleSend = async () => {
    if (!message.trim() || isSending) return;

    const newMessage = message;
    setMessage('');
    setLocalMessages([...localMessages, { role: 'user', content: newMessage }]);

    try {
      setIsSending(true);
      trackFeatureSendMessage(
        index,
        newMessage,
        userMessagesCount + 1,
        currentChat?.id
      );
      const chat = await postMessage(index, newMessage);
      trackFeatureMessageReceived(
        index,
        userMessagesCount + 1,
        currentChat?.id ?? chat?.id
      );
    } finally {
      setIsSending(false);
      setLocalMessages([]);
      invalidate();
    }
  };

  if (isLoading) {
    return (
      <div
        style={{ height: '768px' }}
        className="mt-2 bg-orange-50 p-4 rounded border border-orange-500 border-solid md:w-4/5 w-full"
      >
        <div className="flex flex-col gap-4 h-full justify-between">
          <div className="flex flex-col gap-2">
            <div className="text-center text-gray-500">Carregando...</div>
          </div>
        </div>
      </div>
    );
  }

  const displayMessages = currentChat?.messages?.slice(-10) || [];

  return (
    <ChatProvider currentChat={currentChat} stepIndex={index}>
      <div
        style={{ height: '500px' }}
        className="mt-2 bg-orange-50 p-4 rounded border border-orange-500 border-solid w-full mb-4 relative"
      >
        <div className="absolute -top-4 left-2 select-none">
          <span className="bg-orange-400 text-white text-xs font-semibold px-2 py-1 rounded-full">
            Beta
          </span>
        </div>
        <div className="flex flex-col gap-4 h-full justify-between">
          <div
            ref={messagesContainerRef}
            className="flex flex-col gap-2 overflow-y-auto pr-2"
          >
            <AssistantMessage content="Ficou com dúvida neste passo? Como posso te ajudar?" />
            {displayMessages.map((message, index) =>
              message.role === 'user' ? (
                <UserMessage key={message.id} message={message.content} />
              ) : (
                <AssistantMessage
                  key={message.id}
                  content={message.content}
                  messageId={message.id}
                  feedback={message.feedback}
                  messageIndex={Math.trunc(index / 2)}
                />
              )
            )}
            {localMessages.map((message, i) => (
              <UserMessage key={`local-${i}`} message={message.content} />
            ))}
            {isSending && <LoadingAssistantMessage />}
            <div ref={messagesEndRef} />
          </div>

          <MessageInput
            message={message}
            onInputMessageChange={setMessage}
            isSending={isSending}
            isMaxMessagesReached={isMaxMessagesReached}
            onSend={handleSend}
          />
        </div>
      </div>
    </ChatProvider>
  );
}
