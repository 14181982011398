import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { styled, media } from 'styles';
import {
  listIcon,
  uploadLottie,
  scanningLottie,
  searchingLottie,
} from './assets';
import Lottie from 'react-lottie';
import ListPreviewTooltip from 'modules/lists/components/ListPreviewTooltip';
import { UploadStatus } from '../../hooks/useListUpload';

const {
  NOT_STARTED,
  UPLOADING,
  SPLITTING,
  SEARCHING,
  COMPLETED_WITH_RESULTS,
  COMPLETED_WITHOUT_RESULTS,
} = UploadStatus;

export default function FileThumb({
  file,
  uploadStatus,
  showStatusOverlay,
  uploadTheme,
}) {
  const [fileThumbUrl, setFileThumbUrl] = useState(null);
  const [isThumbHovered, setIsThumbHovered] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const isImage = file.type.startsWith('image/');

    if (isImage) {
      const objectUrl = URL.createObjectURL(file);
      setFileThumbUrl(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);

  const isStatus = useCallback(
    status => {
      return uploadStatus === status;
    },
    [uploadStatus]
  );

  const showThumbTooltip = useMemo(() => {
    return isStatus(NOT_STARTED) && isThumbHovered;
  }, [isStatus, isThumbHovered]);

  const showUploadStatusOverlay = useMemo(() => {
    const visibleStatuses = [
      UPLOADING,
      SPLITTING,
      SEARCHING,
      COMPLETED_WITH_RESULTS,
      COMPLETED_WITHOUT_RESULTS,
    ];

    return visibleStatuses.includes(uploadStatus) && showStatusOverlay;
  }, [uploadStatus, showStatusOverlay]);

  return (
    <div className="relative md:mr-4 rounded-md overflow-hidden">
      <FileImage
        src={fileThumbUrl || listIcon}
        ref={imageRef}
        className="rounded-md"
        onMouseEnter={() => setIsThumbHovered(true)}
        onMouseLeave={() => setIsThumbHovered(false)}
        onError={e => {
          e.target.onerror = null;
          e.target.src = listIcon;
        }}
      />

      {showThumbTooltip && (
        <ListPreviewTooltip
          image={fileThumbUrl}
          triggerElement={imageRef.current}
        />
      )}

      {showUploadStatusOverlay && (
        <UploadStatusOverlay
          uploadStatus={uploadStatus}
          backgroundColor={uploadTheme?.color}
        />
      )}
    </div>
  );
}

const UploadStatusOverlay = ({ uploadStatus, backgroundColor }) => {
  const icons = {
    [UPLOADING]: uploadLottie,
    [SPLITTING]: scanningLottie,
    [SEARCHING]: searchingLottie,
    [COMPLETED_WITH_RESULTS]: searchingLottie,
    [COMPLETED_WITHOUT_RESULTS]: searchingLottie,
  };

  return (
    <div
      className="absolute inset-0 flex items-center justify-center"
      style={{
        backgroundColor: `${backgroundColor}C0`, // C0 is 80% opacity
        backdropFilter: 'blur(1px)',
      }}
    >
      <Lottie
        width={'80%'}
        options={{
          loop: true,
          autoplay: true,
          animationData: icons[uploadStatus],
        }}
      />
    </div>
  );
};

const FileImage = styled.img`
  height: 150px;
  max-width: 150px;
  object-fit: cover;

  @media ${media.mobile} {
    height: 200px;
    max-width: 200px;
  }

  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.05));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.05));
`;
