import { useCallback, useEffect, useState } from 'react';

export default function useFakeProgress() {
  const [progress, setProgress] = useState(5);
  const [inProgress, setInProgress] = useState(false);

  const startProgress = useCallback(() => {
    setProgress(5);
    setInProgress(true);
  }, []);

  const stopProgress = useCallback(() => {
    setInProgress(false);
  }, []);

  const completeProgress = useCallback(() => {
    setInProgress(false);
    setProgress(100);
  }, []);

  const increaseProgress = useCallback(() => {
    if (!inProgress) return;

    setProgress(prev => {
      if (prev <= 99.9) {
        const nextProgress = prev + (100 - prev) * 0.01;
        return nextProgress;
      }
      return prev;
    });
  }, [inProgress]);

  useEffect(() => {
    const interval = setInterval(() => {
      increaseProgress();
    }, 800);

    return () => clearInterval(interval);
  }, [increaseProgress]);

  return { progress, startProgress, stopProgress, completeProgress };
}
