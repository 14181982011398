import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { useCallback, useEffect, useState } from 'react';

const useLearningPath = () => {
  const [learningPaths, setLearningPaths] = useState(null);
  const { data } = useContentQueryImmutable(
    `/learning-path/get-learning-paths`
  );

  const shouldRenderLearningPathDialog = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const flag = searchParams.get('trilha-de-estudos');
    if (flag === 'true') {
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    if (!data) return;
    setLearningPaths(data);
  }, [data]);

  return {
    learningPaths,
    shouldRenderLearningPathDialog,
  };
};

export default useLearningPath;
