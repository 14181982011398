import React from 'react';

import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';

export function Answer({ answer }) {
  if (!answer) return null;

  return (
    <>
      <h1 className="w-full text-orange-400 font-bold md:text-3xl text-2xl">
        Resposta
      </h1>
      <div className="border-l-4 border-0 border-solid border-orange-400 p-5 text-lg">
        <div className="my-8">
          <MathjaxContentWithLatex text={answer} />
        </div>
      </div>
    </>
  );
}
