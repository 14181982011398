import React, { useCallback, useMemo } from 'react';
import { MdChevronRight, MdUpload } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { media } from 'styles';
import { UploadStatus } from '../hooks/useListUpload';
import ThemeSelector from './ThemeSelector';
import { useListWithStatementTracker } from 'modules/mvp/hooks/useListWithStatementTracker';

const {
  NOT_STARTED,
  UPLOADING,
  SPLITTING,
  SEARCHING,
  COMPLETED_WITH_RESULTS,
  COMPLETED_WITHOUT_RESULTS,
  FAILED,
} = UploadStatus;

export default function DialogFooter({
  themes,
  selectedTheme,
  setSelectedTheme,
  handleSubmit,
  uploadStatus,
  uploadProgress,
  listLink,
  listId,
  progressBarAnimationCompleted,
  closeDialog,
}) {
  const { trackChangeUploadTheme } = useListWithStatementTracker();

  const onSelectTheme = useCallback(
    theme => {
      trackChangeUploadTheme({
        oldThemeName: selectedTheme.name,
        newThemeName: theme.name,
      });

      setSelectedTheme(theme);
    },
    [setSelectedTheme, trackChangeUploadTheme, selectedTheme]
  );

  const isStatus = useCallback(
    status => {
      return uploadStatus === status;
    },
    [uploadStatus]
  );

  const isInProgress = useMemo(() => {
    const inProgressStatuses = [
      UPLOADING,
      SPLITTING,
      SEARCHING,
      COMPLETED_WITH_RESULTS,
      COMPLETED_WITHOUT_RESULTS,
    ];

    return (
      inProgressStatuses.includes(uploadStatus) &&
      !progressBarAnimationCompleted
    );
  }, [progressBarAnimationCompleted, uploadStatus]);

  return (
    <div className="w-full">
      {isStatus(NOT_STARTED) && (
        <NotStartedFooter
          themes={themes}
          selectedTheme={selectedTheme}
          onSelectTheme={onSelectTheme}
          handleSubmit={handleSubmit}
        />
      )}

      {isInProgress && (
        <InProgressFooter
          selectedTheme={selectedTheme}
          progress={uploadProgress}
        />
      )}

      {progressBarAnimationCompleted && (
        <SuccessFooter
          closeDialog={closeDialog}
          listLink={listLink}
          listId={listId}
          selectedTheme={selectedTheme}
          uploadStatus={uploadStatus}
        />
      )}

      {isStatus(FAILED) && (
        <FailedFooter closeDialog={closeDialog} selectedTheme={selectedTheme} />
      )}
    </div>
  );
}

const NotStartedFooter = ({
  themes,
  selectedTheme,
  onSelectTheme,
  handleSubmit,
}) => {
  return (
    <div className="w-full flex flex-col md:flex-row md:space-between">
      <ThemeSelectionContainer>
        <span>Enviando lista em</span>
        <ThemeSelector
          themes={themes}
          selectedTheme={selectedTheme}
          onChange={onSelectTheme}
        />
      </ThemeSelectionContainer>

      <Button onClick={handleSubmit} color={selectedTheme.color}>
        <MdUpload size={20} className="mr-2" />
        Enviar
      </Button>
    </div>
  );
};

const InProgressFooter = ({ selectedTheme, progress }) => {
  return (
    <div>
      <ProgressBar theme={selectedTheme} progress={progress} />
    </div>
  );
};

const SuccessFooter = ({
  closeDialog,
  listLink,
  listId,
  selectedTheme,
  uploadStatus,
}) => {
  const { trackClickList } = useListWithStatementTracker();

  const onOpenLink = useCallback(() => {
    trackClickList({
      listId,
      origin: 'Tela de obrigado',
    });
  }, [listId, trackClickList]);

  return (
    <div className="flex flex-col-reverse md:flex-row justify-end gap-2">
      <CloseButton onClick={closeDialog} color={selectedTheme.color} />
      <Link to={listLink} onClick={onOpenLink}>
        <Button color={selectedTheme.color}>
          {uploadStatus === COMPLETED_WITH_RESULTS
            ? 'Ir para a lista'
            : 'Buscar questões parecidas'}
          <MdChevronRight size={20} className="ml-2" />
        </Button>
      </Link>
    </div>
  );
};

const FailedFooter = ({ closeDialog, selectedTheme }) => {
  return (
    <div className="flex justify-end">
      <CloseButton onClick={closeDialog} color={selectedTheme.color} />
    </div>
  );
};

const CloseButton = ({ onClick, color }) => {
  return (
    <Button onClick={onClick} color={color} secondary>
      Fechar
    </Button>
  );
};

const ProgressBar = ({ theme, progress }) => (
  <div className="w-full">
    <div
      className="flex justify-between mb-1 w-full"
      style={{ color: theme.color }}
    >
      <span className="text-base font-medium">
        Enviando lista <span className="md:inline hidden">em {theme.name}</span>
      </span>
      <span className="text-sm font-medium">{`${progress.toFixed(1)}%`}</span>
    </div>
    <div className="w-full bg-gray-100 rounded-full h-2.5">
      <div
        className="h-2.5 rounded-full"
        style={{
          width: `${progress}%`,
          backgroundColor: theme.color,
          transitionProperty: 'width',
          transitionTimingFunction: 'ease-in-out',
          transitionDuration: progress >= 100 ? '2s' : '0.3s',
        }}
      ></div>
    </div>
  </div>
);

const ThemeSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'Lato';
  font-size: 14px;
  color: #555;
  width: 100%;

  @media ${media.mobile} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 18px;
  height: 40px;
  text-transform: uppercase;
  background-color: ${props => (props.secondary ? 'transparent' : props.color)};
  border: none;
  color: ${props => (props.secondary ? '#bbb' : 'white')};
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.2s, background-color 0.2s;
  opacity: 0.9;

  @media ${media.mobile} {
    width: 100%;
  }

  &:hover {
    opacity: 1;
    background-color: ${props => props.secondary && 'rgba(0, 0, 0, 0.03)'};
  }
`;
