import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import useContentRestRequest from 'modules/shared/hooks/requests/useContentRestRequest';
import { useChatLoader } from './ChatLoaderContext';

const ChatFeedbackContext = createContext({});

export function ChatFeedbackProvider({ children }) {
  const { isEligible, invalidate } = useChatLoader();
  const [error, setError] = useState(null);
  const { sendPostRequest } = useContentRestRequest();

  const sendFeedback = useCallback(
    async (messageId, statement, answer, answerGrade) => {
      if (!isEligible) return;

      try {
        setError(null);
        const result = await sendPostRequest('/exercise-chats/feedbacks', {
          messageId,
          statement,
          answer,
          answerGrade,
        });
        invalidate();
        return result;
      } catch (err) {
        setError(err.message || 'Failed to send feedback');
        throw err;
      }
    },
    [sendPostRequest, isEligible, invalidate]
  );

  const value = useMemo(
    () => ({
      error,
      sendFeedback,
      isEligible,
    }),
    [error, sendFeedback, isEligible]
  );

  return (
    <ChatFeedbackContext.Provider value={value}>
      {children}
    </ChatFeedbackContext.Provider>
  );
}

export function useChatFeedback() {
  const context = useContext(ChatFeedbackContext);

  if (!context) {
    throw new Error(
      'useChatFeedback must be used within a ChatFeedbackProvider'
    );
  }

  return context;
}
