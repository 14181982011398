import React from 'react';
import { SmallDexterIAProfile } from './AssistantMessage/SmallDexterIAProfile';

export function LoadingAssistantMessage() {
  return (
    <div className="flex items-end gap-0.5">
      <SmallDexterIAProfile />
      <div className="bg-orange-100 h-10 p-1 rounded-lg max-w-sm text-gray-900 leading-tight text-base flex items-center">
        <div className="flex gap-1">
          <div
            className="w-2 h-2 bg-orange-400 rounded-full animate-bounce"
            style={{ animationDelay: '0ms' }}
          />
          <div
            className="w-2 h-2 bg-orange-400 rounded-full animate-bounce"
            style={{ animationDelay: '150ms' }}
          />
          <div
            className="w-2 h-2 bg-orange-400 rounded-full animate-bounce"
            style={{ animationDelay: '300ms' }}
          />
        </div>
      </div>
    </div>
  );
}
