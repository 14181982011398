import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';
import {
  UserProvider,
  UniversityProvider,
  DialogProvider,
  BannerProvider,
  RollbarProvider,
  FeatureFlagProvider,
  ThemesProvider,
} from './';

export default function RootProviders({ children }) {
  return (
    <RollbarProvider>
      <SWRConfig value={{ provider: () => new Map() }}>
        <BrowserRouter>
          <UserProvider>
            <FeatureFlagProvider>
              <ThemesProvider>
                <DialogProvider>
                  <BannerProvider>
                    <UniversityProvider>{children}</UniversityProvider>
                  </BannerProvider>
                </DialogProvider>
              </ThemesProvider>
            </FeatureFlagProvider>
          </UserProvider>
        </BrowserRouter>
      </SWRConfig>
    </RollbarProvider>
  );
}
