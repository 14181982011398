import React from 'react';
import confetti from 'canvas-confetti';

function ConfettiButton({ options, children, ...props }) {
  const handleClick = event => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = rect.left + rect.width / 2;
    const y = rect.top + rect.height / 2;
    confetti({
      ...options,
      origin: {
        x: x / window.innerWidth,
        y: y / window.innerHeight,
      },
    });
  };

  return (
    <div onClick={handleClick} {...props}>
      {children}
    </div>
  );
}

function confettiSideCannons() {
  const end = Date.now() + 2 * 1000; // 2 seconds
  const colors = ['#a786ff', '#fd8bbc', '#eca184', '#f8deb1'];

  const frame = () => {
    if (Date.now() > end) return;

    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      startVelocity: 60,
      origin: { x: 0, y: 0.5 },
      colors: colors,
      zIndex: 100000,
    });
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      startVelocity: 60,
      origin: { x: 1, y: 0.5 },
      colors: colors,
      zIndex: 100000,
    });

    requestAnimationFrame(frame);
  };

  frame();
}

export { ConfettiButton, confettiSideCannons };
