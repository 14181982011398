import React from 'react';
import { Link } from 'react-router-dom';

export function QuestionNav({ prevQuestion, nextQuestion, listId }) {
  return (
    <div className="flex items-center justify-between w-full bg-white border-t border-0 border-solid border-gray-300 shadow-md rounded-b-md font-bold overflow-hidden">
      {prevQuestion && (
        <Link
          className="flex-1 text-center text-orange-400 flex items-center justify-center text-xl transition-colors duration-200 hover:bg-orange-400 hover:text-white py-3 px-2"
          to={`/lista-de-enunciado/${listId}/questao/${prevQuestion.id}`}
        >
          Anterior
        </Link>
      )}
      {nextQuestion && (
        <Link
          className="flex-4 text-center text-cyan-400 border-0 border-l border-solid border-gray-300 flex items-center justify-center text-xl transition-colors duration-200 hover:bg-cyan-400 hover:text-white md:flex-3 py-3 px-2"
          to={`/lista-de-enunciado/${listId}/questao/${nextQuestion.id}`}
        >
          Ir para o próximo exercício
        </Link>
      )}
    </div>
  );
}
