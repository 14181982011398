import { MathpixMarkdown } from 'mathpix-markdown-it';
import React, { memo } from 'react';
import { styled, fonts, media } from 'styles';

function sanitizeLatex(content) {
  // Fix the issue where the backspace (U+0008) followed by "egin" loses the "b"
  // eslint-disable-next-line no-control-regex
  let result = content.replace(/[\u0008]egin/g, '\\begin');

  // Replace equation environments with \[ ... \]
  result = result.replace(
    /\\begin\{equation\*?\}([\s\S]*?)\\end\{equation\*?\}/g,
    '\\[$1\\]'
  );

  // Replace math expressions that are alone on a line delimited by $...$
  result = result.replace(/^\s*\$(.+?)\$\s*$/gm, (match, mathContent) => {
    return `$${mathContent}$`;
  });

  // Replace $$ ... $$ with \[ ... \]
  result = result.replace(/\$\$(.*?)\$\$/g, (match, mathContent) => {
    return `\\[${mathContent}\\]`;
  });

  // Replace math expressions that are alone on a line delimited by \( ... \)
  result = result.replace(/^\s*\\\((.*?)\\\)\s*$/gm, (match, mathContent) => {
    return `\\[${mathContent}\\]`;
  });

  // Other replacements
  result = result
    .replace(/\(c\)/g, '(c)') // Replace (c) as needed.
    .replace(/^(\d+)\.\s/gm, '') // Remove numbering like "1. " from the start of lines.
    .replace(/^(\d+)\)\s/gm, '') // Remove numbering like "1) " from the start of lines.
    .replace(/\s\\\s/g, '\\\\'); // Escape spaces and backslashes for table linebreaks.

  return result;
}

function MathpixMarkdownContentWrapper({ text, className, marginTop = 30 }) {
  return (
    <OverwriteStyleWrapper className={className} $marginTop={marginTop}>
      <RawMathpixMarkdownContent text={text} />
    </OverwriteStyleWrapper>
  );
}

export default memo(MathpixMarkdownContentWrapper);

export function RawMathpixMarkdownContent({ text }) {
  const sanitizedText = sanitizeLatex(text);

  return (
    <MathpixMarkdown
      text={sanitizedText}
      outMath={{
        include_table_html: true,
        include_table_markdown: true,
        table_markdown: true,
      }}
    />
  );
}

const OverwriteStyleWrapper = styled.div`
  color: #5d5d5d;
  position: relative;
  margin-top: ${props => props.$marginTop}px;

  #content {
    #preview {
      padding: 0 !important;
    }
  }

  h1,
  h2,
  div,
  p {
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }

  * {
    user-select: none !important;
    -webkit-user-select: none !important;
    /* Disable selection/copy in UIWebView */

    font-style: normal;
  }

  h2:empty {
    display: none;
  }

  img {
    max-width: 804px;
    margin: 0 auto;
    display: block;
  }

  p,
  h1,
  h3,
  li {
    font-family: ${fonts.serif};
    font-weight: 400;
  }

  ul,
  ol {
    margin-left: 15px;
    padding-left: 10px;
  }

  h1,
  b {
    color: #444;
  }

  b {
    font-weight: 700;
  }

  h1 {
    font-size: 1.7em;
    margin-top: 35px;
    line-height: 33px;
  }

  h2 {
    font-size: 1.55em;
    margin-top: 10px;
    line-height: 31px;
    padding-bottom: 30px;
  }

  p,
  li {
    font-size: 1.25em;
    line-height: 26px;
    color: #444;
    padding-bottom: 15px;

    @media ${media.mobile} {
      font-size: 1.1em;
    }
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 1.5em;
    margin-left: 1.3em;
  }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
  }

  ul li {
    list-style-type: disc;
  }

  p {
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media ${media.mobile} {
    .MJX-TEX {
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  mjx-container {
    text-align: start !important;
  }

  p,
  ol,
  ul,
  h1,
  img,
  mjx-container {
    margin-top: 5px;
  }
`;
