import React from 'react';
import { Send } from '@material-ui/icons';

export function MessageInput({
  message,
  onInputMessageChange,
  isSending,
  isMaxMessagesReached,
  onSend,
}) {
  const handleKeyPress = e => {
    if (e.key === 'Enter' && !isSending) {
      onSend();
    }
  };

  return (
    <div className="flex gap-2">
      <input
        type="text"
        value={message}
        onChange={e => onInputMessageChange(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder={
          isMaxMessagesReached
            ? 'Limite de mensagens atingido'
            : 'Digite sua dúvida...'
        }
        disabled={isSending || isMaxMessagesReached}
        className="flex-1 p-2 rounded-lg border border-transparent focus:outline-none focus:border-orange-500 disabled:opacity-50 disabled:cursor-not-allowed text-gray-900 h-10"
        maxLength={280}
      />
      <button
        onClick={onSend}
        disabled={isSending || !message.trim() || isMaxMessagesReached}
        className="w-10 h-10 rounded-lg bg-orange-400 text-white flex items-center justify-center hover:bg-orange-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <Send className="h-5 w-5" />
      </button>
    </div>
  );
}
