import React, { useCallback, useEffect, useState } from 'react';
import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';
import { useListWithStatementQuestionAccess } from 'modules/mvp/providers/ListWithStatementQuestionAccessProvider';
import { useTrackEvents } from 'modules/mvp/hooks';

import { SolutionSection } from './Solution';

export function QuestionWithResponse({ question, setCheckSearchResult, list }) {
  const [showResolution, setShowResolution] = useState(false);
  const { trackViewQuestion, trackClickToSeeSolutionButton } = useTrackEvents();
  const { processQuestionCredit } = useListWithStatementQuestionAccess();

  useEffect(() => {
    if (!question) return;

    trackViewQuestion(question, question.listWithStatement);
  }, [question, trackViewQuestion]);

  const toggleShowResolution = useCallback(async () => {
    const newShowResolution = !showResolution;
    if (newShowResolution) trackClickToSeeSolutionButton(list, question);
    setShowResolution(newShowResolution);

    if (newShowResolution) processQuestionCredit();
  }, [
    list,
    question,
    showResolution,
    trackClickToSeeSolutionButton,
    processQuestionCredit,
  ]);

  const showAnswerButtonCopy = useCallback(() => {
    return showResolution ? 'Esconder resolução' : 'Ver resolução';
  }, [showResolution]);

  return (
    <div
      className="flex flex-col max-w-full mx-auto pt-5"
      style={{
        width: '800px',
      }}
    >
      <div className="w-full text-orange-400 font-bold text-2xl underline">
        Questão {question.label} da Lista
      </div>
      <MathjaxContentWithLatex
        className="text-gray-600 mt-4"
        text={question.exercise.lightBody}
      />
      <button
        className="w-72 max-w-full py-3 mt-2 mx-auto bg-orange-400 text-white text-lg font-bold rounded-full"
        onClick={toggleShowResolution}
      >
        {showAnswerButtonCopy()}
      </button>
      {!showResolution && (
        <div
          className="underline mx-auto mt-4 text-gray-700 cursor-pointer"
          onClick={() => setCheckSearchResult(true)}
        >
          Não parece a questão da minha lista
        </div>
      )}

      <SolutionSection
        list={list}
        question={question}
        showResolution={showResolution}
      />
    </div>
  );
}
