import React, { useState, useCallback } from 'react';
import { useChatFeedback } from 'modules/mvp/contexts/ListWithStatementQuestionPage';
import { useChatTrackEvents } from 'modules/mvp/contexts/ListWithStatementQuestionPage';
import { useChat } from 'modules/mvp/contexts/ListWithStatementQuestionPage';
import { useDialogContext } from 'providers/DialogProvider';
import { FeedbackDialog } from './FeedbackDialog';

const ANSWER_GRADE_OPTIONS = ['Não ajudou', 'Pode melhorar', 'Ajudou muito'];

export function ChatMessageFeedback({ messageId, messageIndex, feedback }) {
  const { chatId, stepIndex } = useChat();
  const [incomingAnswerGrade, setIncomingAnswerGrade] = useState(
    feedback?.answerGrade || null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(() =>
    feedback && feedback.answerGrade !== null ? true : false
  );
  const { sendFeedback } = useChatFeedback();
  const { trackFeatureFeedback, trackFeedbackSubmit } = useChatTrackEvents();
  const { setDialog } = useDialogContext();

  const statement = 'O que achou desta resposta?';
  const answerGrade = feedback?.answerGrade ?? incomingAnswerGrade;

  const handleDialogSubmit = useCallback(
    (correctness, correctnessIndex, clarity, clarityIndex, details) => {
      trackFeedbackSubmit(
        messageId,
        messageIndex,
        stepIndex,
        chatId,
        correctness,
        correctnessIndex,
        clarity,
        clarityIndex,
        details
      );
    },
    [trackFeedbackSubmit, messageId, messageIndex, stepIndex, chatId]
  );

  const handleFeedbackClick = async grade => {
    if (hasSubmitted || isSubmitting) return;

    setIsSubmitting(true);
    setIncomingAnswerGrade(grade);
    setHasSubmitted(true);

    const answer = ANSWER_GRADE_OPTIONS[grade];

    try {
      await Promise.all([
        sendFeedback(messageId, statement, answer, grade),
        setDialog(<FeedbackDialog onSubmit={handleDialogSubmit} />),
        Promise.resolve(
          trackFeatureFeedback(
            messageId,
            messageIndex,
            stepIndex,
            chatId,
            statement,
            answer,
            grade
          )
        ),
      ]);
    } catch (error) {
      setIncomingAnswerGrade(null);
      setHasSubmitted(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row md:gap-2 gap-1 mb-4 md:text-sm text-xs">
      <div className="md:block hidden text-gray-500">{statement}</div>
      <div className="flex flex-wrap gap-1">
        {ANSWER_GRADE_OPTIONS.map((option, index) => (
          <ChatMessageFeedbackButton
            key={index}
            onClick={() => handleFeedbackClick(index)}
            isSelected={answerGrade === index}
            hasSubmitted={hasSubmitted}
            disabled={isSubmitting || hasSubmitted}
          >
            {option}
          </ChatMessageFeedbackButton>
        ))}
      </div>
    </div>
  );
}

export function ChatMessageFeedbackButton({
  children,
  isSelected,
  hasSubmitted,
  onClick,
}) {
  return (
    <div
      className={`py-1 px-2 border border-gray-300 rounded-lg cursor-pointer bg-white select-none whitespace-nowrap ${
        isSelected ? 'bg-gray-400 text-white' : ''
      } ${hasSubmitted ? 'opacity-50 cursor-not-allowed' : ''}`}
      onClick={onClick}
      disabled={hasSubmitted}
    >
      {children}
    </div>
  );
}
