import React, { useCallback, useEffect } from 'react';

import { media } from 'styles';
import { Loading, Error } from 'modules/shared/components/generic';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import useRenderThemesPageDialog from '../hooks/useRenderThemesPageDialog';
import ProfessorProvider from 'modules/professors/providers/ProfessorProvider';
import HomeDesktop from './HomeDesktop';
import HomeMobile from './HomeMobile';
import { useCookies } from 'modules/shared/hooks/useCookies';
import { useThemesContext } from 'providers/ThemesProvider';

export default function Home() {
  const {
    themes,
    themesLoading: loading,
    themesError: error,
    setCurrentThemeId,
    currentTheme,
  } = useThemesContext();

  const {
    data: disciplines,
    loading: disciplineLoading,
    error: disciplineError,
  } = useContentQueryImmutable(`/disciplines`);

  const {
    data: listsData,
    loading: listsLoading,
    error: listsError,
    mutate: invalidateListsData,
  } = useContentQueryImmutable(
    `/list-with-statements`,
    {},
    { revalidateOnMount: true }
  );

  const { data: studyPlans } = useContentQueryImmutable(
    `/study-plans`,
    {},
    { revalidateOnMount: true }
  );

  // const {
  //   data: studyTracks,
  //   loading: studyTracksLoading,
  //   error: studyTracksError,
  // } = useContentQueryImmutable(`/study-tracks`);

  const [cookies, setCookie, deleteCookie] = useCookies(['lastSelectedTheme']);
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();
  useRenderThemesPageDialog();
  const { amplitudeStartSessionReply } = useTracker();

  useEffect(() => {
    const lastSelectedTheme = cookies.lastSelectedTheme;

    if (lastSelectedTheme) {
      const lastTheme = themes?.find(t => t.id === parseInt(lastSelectedTheme));

      if (lastTheme) {
        setCurrentThemeId(lastTheme.id);
      }
    } else if (media.isDesktop()) {
      setCurrentThemeId(themes?.[0]?.id);
    }
  }, [cookies, themes, setCurrentThemeId]);

  useEffect(() => {
    trackEvent('Visualizou Home', {
      ...getUTMsByLocation(window.location),
      Plataforma: 'Web',
      Versão: 'Livros e Listas em destaque',
    });

    trackEvent('MVP Lista: Visualizou Banner', {
      Onde: 'Matérias',
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Versão: '2.1',
    });

    amplitudeStartSessionReply(0.2); // 20%
  }, [getUTMsByLocation, trackEvent, amplitudeStartSessionReply]);

  const onSelectTheme = useCallback(
    theme => {
      if (theme) {
        setCookie('lastSelectedTheme', theme.id, {
          maxAge: 24 * 60 * 60, // 1 day
        });
      } else {
        deleteCookie('lastSelectedTheme');
      }

      setCurrentThemeId(theme?.id || null);
    },
    [setCookie, deleteCookie, setCurrentThemeId]
  );

  // const shouldMarkThemeAsPrivate = useCallback(theme => {
  // if (!studyTracks || studyTracks.length === 0) return false;

  // theme.studyTracks = studyTracks.filter(
  //   studyTrack => studyTrack.themeId === theme.id
  // );
  // const themeHasAtLeastOnePrivateSubject = theme.studyTracks.length > 0;

  // return (
  //   isFromPrivateUniversityCampaign && themeHasAtLeastOnePrivateSubject
  // );
  // }, []);

  // const generalThemes = useMemo(() => {
  //   if (!themes) return [];

  //   return themes.filter(t => !shouldMarkThemeAsPrivate(t));
  // }, [themes, shouldMarkThemeAsPrivate]);

  // const privateThemes = useMemo(() => {
  //   if (!themes) return [];

  //   return themes.filter(t => shouldMarkThemeAsPrivate(t));
  // }, [themes, shouldMarkThemeAsPrivate]);

  if (error || disciplineError || listsError)
    // || studyTracksError
    return <Error />;
  if (loading || disciplineLoading || listsLoading)
    // || studyTracksLoading
    return <Loading />;

  return (
    <ProfessorProvider>
      {media.isMobile() ? (
        <HomeMobile
          // privateThemes={privateThemes}
          generalThemes={themes}
          disciplines={disciplines}
          selectedTheme={currentTheme}
          onSelectTheme={onSelectTheme}
          studyPlans={studyPlans}
          lists={listsData}
          onListsMutation={invalidateListsData}
        />
      ) : (
        <HomeDesktop
          // privateThemes={privateThemes}
          generalThemes={themes}
          disciplines={disciplines}
          studyPlans={studyPlans}
          selectedTheme={currentTheme}
          onSelectTheme={onSelectTheme}
          lists={listsData}
          onListsMutation={invalidateListsData}
        />
      )}
    </ProfessorProvider>
  );
}
