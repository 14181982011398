import React, { useMemo } from 'react';

import { LoginDialog } from 'modules/shared/dialogs';
import { useRenderDialog } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import { BookCreditCardTrialCTADialog } from 'modules/campaigns/book-trial/components';

export function useRenderSearchedExercisePageDialog() {
  const { user } = useUserContext();
  const { showBookCreditCardTrialCTA } = useBookTrialCampaign();

  const dialogs = useMemo(() => {
    return [
      {
        condition: () => !user,
        render: () => <LoginDialog />,
      },
      {
        condition: () => showBookCreditCardTrialCTA(),
        render: () => <BookCreditCardTrialCTADialog source="Questão Buscada" />,
      },
    ];
  }, [user, showBookCreditCardTrialCTA]);

  useRenderDialog(dialogs);
}
