import React, { useCallback, useState, useEffect } from 'react';
import { media, styled } from 'styles';
import { MdClose } from 'react-icons/md';
import { useDialogContext } from 'providers/DialogProvider';
import useListUpload, { UploadStatus } from './hooks/useListUpload';
import { confettiSideCannons } from './components/Confetti';
import DialogFooter from './components/DialogFooter';
import FileThumb from './components/FileThumb/FileThumb';
import UploadStatusInfo from './components/UploadStatusInfo/UploadStatusInfo';
import { Link } from 'react-router-dom';
import { useListWithStatementTracker } from 'modules/mvp/hooks/useListWithStatementTracker';
import { useMvpBooksTracker } from 'modules/campaigns/mvp-books/hooks/useMVpBooksTracker';

const {
  NOT_STARTED,
  COMPLETED_WITH_RESULTS,
  COMPLETED_WITHOUT_RESULTS,
  FAILED,
} = UploadStatus;

export default function ListUploadDialog({
  file,
  themes,
  defaultTheme,
  onClose = () => {},
  onListUpload = () => {},
  utms = null,
}) {
  const { unsetDialog } = useDialogContext();
  const [selectedTheme, setSelectedTheme] = useState(defaultTheme);

  const [
    progressBarAnimationCompleted,
    setProgressBarAnimationCompleted,
  ] = useState(false);

  const {
    startUpload,
    uploadStatus,
    uploadProgress,
    uploadData,
    foundPercentage,
    getListLink,
  } = useListUpload(file);

  const {
    trackClickSendListWithStatement,
    trackViewThankYouScren,
  } = useListWithStatementTracker();

  const { trackCloseDialog } = useMvpBooksTracker();

  const closeDialog = useCallback(() => {
    const closeScreen = {
      [NOT_STARTED]: 'Enviar Lista Enunciado',
      [COMPLETED_WITH_RESULTS]: 'Obrigado Lista com Enunciado',
      [COMPLETED_WITHOUT_RESULTS]: 'Obrigado Lista com Enunciado',
      [FAILED]: 'Não conseguimos resolver sua lista',
    };

    trackCloseDialog(closeScreen[uploadStatus]);
    onClose();
    unsetDialog();
  }, [unsetDialog, onClose, trackCloseDialog, uploadStatus]);

  const handleSubmit = useCallback(() => {
    startUpload(selectedTheme.id);

    trackClickSendListWithStatement({
      fileName: file.name,
      fileType: file.type,
      themeName: selectedTheme.name,
    });
  }, [startUpload, selectedTheme, file, trackClickSendListWithStatement]);

  const goToResultScreen = useCallback(() => {
    console.log('onListUpload', onListUpload);
    //onListUpload && onListUpload();
    setProgressBarAnimationCompleted(true);

    if (uploadStatus === COMPLETED_WITH_RESULTS) {
      confettiSideCannons();
    }

    trackViewThankYouScren({
      listLink: getListLink(true),
      theme: selectedTheme.name,
      foundPercentage,
    });
  }, [
    foundPercentage,
    getListLink,
    selectedTheme,
    onListUpload,
    trackViewThankYouScren,
    uploadStatus,
  ]);

  useEffect(() => {
    if (
      uploadStatus === COMPLETED_WITH_RESULTS ||
      uploadStatus === COMPLETED_WITHOUT_RESULTS
    ) {
      setTimeout(goToResultScreen, 3000);
    }
  }, [uploadStatus, goToResultScreen]);

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="w-11/12 max-w-2xl bg-white rounded-xl shadow-md p-3 md:p-4">
        {/* Dialog Main Content */}
        <MainContent
          link={progressBarAnimationCompleted ? getListLink(false, utms) : null}
        >
          <FileThumb
            file={file}
            uploadStatus={uploadStatus}
            showStatusOverlay={!progressBarAnimationCompleted}
            uploadTheme={selectedTheme}
          />

          <UploadStatusInfo
            file={file}
            uploadStatus={uploadStatus}
            uploadTheme={selectedTheme}
            showResult={progressBarAnimationCompleted}
          />

          {uploadStatus === NOT_STARTED && (
            <RemoveFileButton onClick={closeDialog} />
          )}
        </MainContent>

        {/* Dialog Footer (actions) */}
        <DialogFooter
          themes={themes}
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
          handleSubmit={handleSubmit}
          uploadStatus={uploadStatus}
          uploadProgress={uploadProgress}
          listLink={getListLink(false, utms)}
          listId={uploadData?.listId}
          progressBarAnimationCompleted={progressBarAnimationCompleted}
          closeDialog={closeDialog}
        />
      </div>
    </div>
  );
}

function MainContent({ link, children }) {
  const classes =
    'w-full border bg-gray-100 border-gray-200 p-4 mb-4 flex flex-col md:flex-row items-center rounded-lg relative transition-colors duration-200';

  return link ? (
    <Link to={link} className={`${classes} hover:bg-gray-200`}>
      {children}
    </Link>
  ) : (
    <div className={classes}>{children}</div>
  );
}

const RemoveFileButton = styled(MdClose)`
  color: #999;
  font-size: 1.25rem;
  cursor: pointer;
  margin-left: 8px;
  flex-shrink: 0;

  @media ${media.mobile} {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  &:hover {
    color: #666;
    scale: 1.1;
  }
`;
