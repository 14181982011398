import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import { ChatMessageFeedback } from './Feedback';

import { SmallDexterIAProfile } from './SmallDexterIAProfile';
import { RawMathpixMarkdownContent } from 'modules/shared/components/generic';

export function AssistantMessage({
  content,
  messageId,
  messageIndex,
  feedback,
}) {
  return (
    <div className="flex items-start gap-0.5">
      <SmallDexterIAProfile />
      <div className="flex gap-2 flex-col">
        <div className="bg-orange-100 p-1 rounded-lg w-full text-gray-900 leading-tight text-base">
          <CompactStyleWrapper>
            <RawMathpixMarkdownContent text={content} />
          </CompactStyleWrapper>
        </div>
        {messageId && (
          <ChatMessageFeedback
            messageId={messageId}
            messageIndex={messageIndex}
            feedback={feedback}
          />
        )}
      </div>
    </div>
  );
}

const CompactStyleWrapper = styled.div`
  color: inherit;
  position: relative;

  #content {
    #preview {
      padding: 0 !important;
    }
  }

  h1,
  h2,
  div,
  p {
    margin: 0px 0px 8px 0px;
    padding: 0;
    line-height: 1.4;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p,
  h1,
  div,
  h3 {
    font-weight: 400;
  }

  ul,
  ol {
    margin-left: 5px;
    padding-left: 5px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  b {
    color: #444;
  }

  b {
    font-weight: 700;
  }

  h1 {
    font-size: 1.5em;
    margin-top: 25px;
    line-height: 33px;
  }

  h2 {
    font-size: 1.2em;
    margin-top: 10px;
    line-height: 31px;
    padding-bottom: 30px;
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
  }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
  }

  ul li {
    list-style-type: disc;
  }

  p {
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media ${media.mobile} {
    .MJX-TEX {
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  mjx-container {
    text-align: start !important;
  }

  p,
  ol,
  ul,
  h1,
  img,
  mjx-container {
    margin-top: 5px;
  }
`;
