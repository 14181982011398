import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { media } from 'styles';
import { useChatLoader } from './ChatLoaderContext';

const ChatTrackEventsContext = createContext({});

export function ChatTrackEventsProvider({
  children,
  numberOfSteps,
  openShowResolution,
  location,
  exercise,
  anotherDefaultProperties = {},
}) {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();
  const [alreadyFiredViewEvent, setAlreadyFiredViewEvent] = useState(false);
  const { isEligible } = useChatLoader();

  const defaultProperties = useMemo(() => {
    return {
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Versão: '0.0.1',
      'Quantidade de Passos do Exercício': numberOfSteps,
      ...getUTMsByLocation(window.location),
      Onde: location,
      'Tipo de Exercício': exercise.exerciseType,
      'ID do Exercício': exercise.id,
      ...anotherDefaultProperties,
    };
  }, [
    getUTMsByLocation,
    numberOfSteps,
    anotherDefaultProperties,
    location,
    exercise,
  ]);

  const getStepNumber = useCallback(stepIndex => stepIndex + 1, []);

  const trackFeatureView = useCallback(() => {
    if (alreadyFiredViewEvent) return;

    trackEvent('Não Entendi: Visualizou Feature', {
      ...defaultProperties,
      Elegivel: true,
    });

    setAlreadyFiredViewEvent(true);
  }, [defaultProperties, trackEvent, alreadyFiredViewEvent]);

  const trackFeatureClick = useCallback(
    stepIndex => {
      trackEvent('Não Entendi: Clicou Abrir Chat', {
        ...defaultProperties,
        'Passo da Dúvida': getStepNumber(stepIndex),
      });
    },
    [defaultProperties, trackEvent, getStepNumber]
  );

  const trackFeatureClose = useCallback(
    stepIndex => {
      trackEvent('Não Entendi: Fechou Chat', {
        ...defaultProperties,
        'Passo da Dúvida': getStepNumber(stepIndex),
      });
    },
    [defaultProperties, trackEvent, getStepNumber]
  );

  const trackFeatureSendMessage = useCallback(
    (stepIndex, message, userMessageNumber, chatId) => {
      trackEvent('Não Entendi: Enviou Mensagem', {
        ...defaultProperties,
        'Passo da Dúvida': getStepNumber(stepIndex),
        'Número da Mensagem do Usuário': userMessageNumber,
        'Mensagem do Usuário': message,
        'ID do Chat': chatId ?? null,
      });
    },
    [defaultProperties, trackEvent, getStepNumber]
  );

  const trackFeatureMessageReceived = useCallback(
    (stepIndex, userMessageNumber, chatId) => {
      trackEvent('Não Entendi: Recebeu Resposta', {
        ...defaultProperties,
        'Passo da Dúvida': getStepNumber(stepIndex),
        'Número da Mensagem do Usuário': userMessageNumber,
        'ID do Chat': chatId,
      });
    },
    [defaultProperties, trackEvent, getStepNumber]
  );

  const trackFeatureError = useCallback(
    (stepIndex, errorMessage) => {
      trackEvent('Não Entendi: Erro ao Processar Mensagem', {
        ...defaultProperties,
        'Passo da Dúvida': getStepNumber(stepIndex),
        'Mensagem de Erro': errorMessage,
      });
    },
    [defaultProperties, trackEvent, getStepNumber]
  );

  const trackFeatureFeedback = useCallback(
    (
      messageId,
      messageIndex,
      stepIndex,
      chatId,
      question,
      answer,
      answerGrade
    ) => {
      trackEvent('Não Entendi: Enviou Feedback Geral', {
        ...defaultProperties,
        'ID da Mensagem': messageId,
        'Número da Mensagem da IA': messageIndex + 1,
        'Passo da Dúvida': getStepNumber(stepIndex),
        'ID do Chat': chatId,
        Pergunta: question,
        Resposta: answer,
        'Nota da Resposta': answerGrade,
      });
    },
    [defaultProperties, trackEvent, getStepNumber]
  );

  const trackFeedbackSubmit = useCallback(
    (
      messageId,
      messageIndex,
      stepIndex,
      chatId,
      correctness,
      correctnessIndex,
      clarity,
      clarityIndex,
      details
    ) => {
      trackEvent('Não Entendi: Enviou Feedback Detalhado', {
        ...defaultProperties,
        'ID da Mensagem': messageId,
        'Número da Mensagem da IA': messageIndex + 1,
        'Passo da Dúvida': getStepNumber(stepIndex),
        'ID do Chat': chatId,
        'Resposta Assertividade': correctness,
        'Nota Assertividade': correctnessIndex,
        'Resposta Didática': clarity,
        'Nota Didática': clarityIndex,
        Observações: details,
      });
    },
    [defaultProperties, trackEvent, getStepNumber]
  );

  const trackFeatureEvent = useCallback(
    (eventName, properties = {}) => {
      trackEvent(eventName, {
        ...defaultProperties,
        ...properties,
      });
    },
    [defaultProperties, trackEvent]
  );

  useEffect(() => {
    if (isEligible && openShowResolution) {
      trackFeatureView();
    }
  }, [isEligible, openShowResolution, trackFeatureView]);

  const value = useMemo(
    () => ({
      trackFeatureView,
      trackFeatureClick,
      trackFeatureClose,
      trackFeatureSendMessage,
      trackFeatureMessageReceived,
      trackFeatureError,
      trackFeatureFeedback,
      trackFeedbackSubmit,
      trackFeatureEvent,
      numberOfSteps,
    }),
    [
      trackFeatureView,
      trackFeatureClick,
      trackFeatureClose,
      trackFeatureSendMessage,
      trackFeatureMessageReceived,
      trackFeatureError,
      trackFeatureFeedback,
      trackFeedbackSubmit,
      trackFeatureEvent,
      numberOfSteps,
    ]
  );

  return (
    <ChatTrackEventsContext.Provider value={value}>
      {children}
    </ChatTrackEventsContext.Provider>
  );
}

export function useChatTrackEvents() {
  const context = useContext(ChatTrackEventsContext);

  if (!context) {
    throw new Error(
      'useChatTrackEvents must be used within a ChatTrackEventsProvider'
    );
  }

  return context;
}
