import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { media, styled } from 'styles';
import { BookIconsContainer, BookContentFeedbackContainer } from '..';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useCookies } from 'modules/shared/hooks';
import ExerciseStatement from '../ExerciseStatement';
import Solution from './Solution';
import Answer from './Answer';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import { useBookExercisePermission } from 'modules/books/hooks';
import useSearchElegebility from 'modules/search/hooks/useSearchElegibility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import { useSEOQuestionTrialContext } from 'modules/books/providers/SEOQuestionTrialProvider';
import { useFeatureFlagContext } from 'providers/FeatureFlagProvider';

function BookSolution({ primaryColor, solvedByAi }) {
  const {
    bookExercise,
    currentExerciseId,
    edition,
    numViewsExerciseStatement,
  } = useBookEditionContext();
  const { canViewBookExercise } = useBookExercisePermission(edition);
  const { shouldShowExerciseStatement } = useSearchElegebility();

  const { hasQuestionTrial } = useSEOQuestionTrialContext();
  const { hasFlagNewHome } = useFeatureFlagContext();

  const [cookies] = useCookies(['books_result']);
  const cookieFromSearch = cookies.books_result;

  const logSearchTrackingTimeReq = useRAPostRequest(
    'search/log_search_tracking_time'
  );

  const blur = useMemo(() => {
    const seoTrialPermission = hasQuestionTrial(currentExerciseId);
    if (seoTrialPermission) return false;

    return !canViewBookExercise(currentExerciseId);
  }, [canViewBookExercise, currentExerciseId, hasQuestionTrial]);

  const shouldShowContentFeedback = useCallback(() => {
    return canViewBookExercise(currentExerciseId);
  }, [canViewBookExercise, currentExerciseId]);

  const shouldShowAnswer = useCallback(() => {
    return bookExercise?.lightAnswer !== '';
  }, [bookExercise]);

  const showStatementBecauseOfSEO = useCallback(() => {
    const cameFromSEO = !!Cookies.get('seo_last_page');
    const isFirstStatementView = numViewsExerciseStatement <= 1;

    if (cameFromSEO && isFirstStatementView) {
      return true;
    }
  }, [numViewsExerciseStatement]);

  const showStatementBecauseOfSearch = useCallback(() => {
    if (shouldShowExerciseStatement()) return true;
    if (!cookieFromSearch) return false;

    if (parseInt(cookieFromSearch.bookExerciseId) === bookExercise?.id) {
      return true;
    }
  }, [cookieFromSearch, bookExercise, shouldShowExerciseStatement]);

  const showStatement = useMemo(() => {
    return showStatementBecauseOfSEO() || showStatementBecauseOfSearch();
  }, [showStatementBecauseOfSEO, showStatementBecauseOfSearch]);

  const termSearched = useMemo(() => {
    if (!cookieFromSearch) return '';

    return cookieFromSearch.searchTerm;
  }, [cookieFromSearch]);

  useEffect(() => {
    if (!termSearched) return;

    // esse é um log em duas etapas.
    // a ideia é ter nocao do tempo que o usuario fica no resultado da busca
    // primeiros 2s trackeamos a cada 200ms
    // depois disso trackeamos a cada 5s

    const start = new Date();

    function sendLog() {
      const time = +new Date() - start;

      const json = {
        url: location.href.split('?')[0],
        term: termSearched,
        time: time,
      };

      logSearchTrackingTimeReq(json);
    }

    let interval;
    let counter = 0;
    const firstInterval = setInterval(() => {
      counter++;

      sendLog();

      if (counter === 10) {
        interval = setInterval(() => {
          sendLog();
        }, 5000);

        clearInterval(firstInterval);
      }
    }, 200);

    return () => {
      clearInterval(firstInterval);
      clearInterval(interval);
    };
  }, [bookExercise, termSearched, logSearchTrackingTimeReq]);

  return (
    <ContentContainer>
      {!hasFlagNewHome() && <BookIconsContainer />}
      {showStatement && (
        <ExerciseStatement
          termSearched={termSearched}
          solvedByAi={solvedByAi}
          primaryColor={primaryColor}
        />
      )}
      {solvedByAi && (
        <SolvedByAiTag>
          Resolvida por IA <FontAwesomeIcon icon={faMagic} />
        </SolvedByAiTag>
      )}
      <Solution
        blur={blur}
        primaryColor={primaryColor}
        solvedByAi={solvedByAi}
      />
      {shouldShowAnswer() && (
        <Answer
          blur={blur}
          primaryColor={primaryColor}
          solvedByAi={solvedByAi}
        />
      )}
      {shouldShowContentFeedback() && <BookContentFeedbackContainer />}
    </ContentContainer>
  );
}

export default memo(BookSolution);

const ContentContainer = styled.div`
  position: relative;
  flex: 1;
  align-self: center;
  background-color: white;
  width: 100%;
  padding: 0 20px;

  @media ${media.mobile} {
    padding: 0 10px;
  }

  .blurs {
    filter: blur(5px);
  }
`;

const SolvedByAiTag = styled.div`
  display: block;
  width: fit-content;
  margin-left: 20px;
  margin-bottom: 20px;
  background-color: #6a0d91;
  padding: 10px 20px;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;

  svg {
    margin-left: 5px;
  }
`;
