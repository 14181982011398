import { useListWithStatementTracker } from 'modules/mvp/hooks/useListWithStatementTracker';
import { useToast } from 'modules/shared/hooks';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useCallback, useEffect, useRef, useState } from 'react';
import useFakeProgress from './useFakeProgress';
import { eligibleAiThemes } from 'modules/mvp/utils';

export const UploadStatus = {
  NOT_STARTED: 'not_started',
  UPLOADING: 'uploading',
  SPLITTING: 'splitting',
  SEARCHING: 'searching',
  COMPLETED_WITH_RESULTS: 'completed_with_results',
  COMPLETED_WITHOUT_RESULTS: 'completed_without_results',
  FAILED: 'failed',
};

const {
  NOT_STARTED,
  UPLOADING,
  SPLITTING,
  SEARCHING,
  COMPLETED_WITH_RESULTS,
  COMPLETED_WITHOUT_RESULTS,
  FAILED,
} = UploadStatus;

export default function useListUpload(file) {
  const [uploadThemeId, setUploadThemeId] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(NOT_STARTED);
  const [uploadError, setUploadError] = useState(null);
  const [uploadData, setUploadData] = useState(null);
  const [foundPercentage, setFoundPercentage] = useState(0);
  const pollTimeoutRef = useRef(null);

  const { sendPostFileRequest, sendGetRequest } = useContentRestRequest();
  const {
    progress,
    startProgress,
    stopProgress,
    completeProgress,
  } = useFakeProgress();

  const { trackSendError } = useListWithStatementTracker();
  const { errorToast } = useToast();

  const onSuccess = useCallback(
    data => {
      const { foundPercentage } = data;

      const eligibleToAi = eligibleAiThemes.includes(uploadThemeId);
      const isCompletedWithResults = eligibleToAi || foundPercentage > 0;

      if (isCompletedWithResults) {
        setUploadStatus(COMPLETED_WITH_RESULTS);
      } else {
        setUploadStatus(COMPLETED_WITHOUT_RESULTS);
      }

      setFoundPercentage(foundPercentage ?? 0);
      completeProgress();
    },
    [setUploadStatus, setFoundPercentage, completeProgress, uploadThemeId]
  );

  const onFail = useCallback(
    (err, trackingMessage) => {
      setUploadStatus(FAILED);
      stopProgress();
      setUploadError(err);
      trackSendError(trackingMessage);
      console.log('ListUpload Error:', err);
      errorToast(
        err?.message || 'Alguma coisa deu errado, tente novamente mais tarde'
      );
    },
    [errorToast, trackSendError, setUploadStatus, setUploadError, stopProgress]
  );

  const getListLink = useCallback(
    (fullUrl = false, utms = null) => {
      const listId = uploadData?.listId;
      if (!listId) return null;

      const domain = fullUrl ? process.env.REACT_APP_REACT_URL : '';
      const link = `${domain}/lista-de-enunciado/${listId}`;
      if (!utms) return link;

      const { utmSource, utmMedium, utmContent } = utms;
      const utmParams = `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}`;
      return `${link}${utmParams}`;
    },
    [uploadData]
  );

  const getListStatus = useCallback(
    listId => {
      const url = `/list-with-statements/${listId}/status`;
      return sendGetRequest(url);
    },
    [sendGetRequest]
  );

  const pollListStatus = useCallback(
    async listId => {
      try {
        const { data } = await getListStatus(listId);
        const { status, failReason } = data;

        if (status === 'success') {
          onSuccess(data);
        } else if (status === 'failed') {
          onFail(failReason, 'Erro ao processar lista');
        } else {
          // If it's the first poll, wait 15 seconds
          // Otherwise, wait 5 seconds
          const delay = pollTimeoutRef.current ? 5000 : 15000;

          pollTimeoutRef.current = setTimeout(() => {
            pollListStatus(listId);
          }, delay);
        }
      } catch (err) {
        onFail(err, 'Erro ao processar lista');
      }
    },
    [onSuccess, onFail, getListStatus, pollTimeoutRef]
  );

  const postUploadFile = useCallback(
    (file, themeId) => {
      return sendPostFileRequest('/list-with-statements/upload', file, {
        themeId,
      });
    },
    [sendPostFileRequest]
  );

  const startUpload = useCallback(
    async themeId => {
      try {
        setUploadStatus(UPLOADING);
        setUploadThemeId(themeId);
        startProgress();

        const { data, error } = await postUploadFile(file, themeId);
        if (error) return onFail(error, 'Erro ao enviar lista');

        setUploadData(data);
        setUploadStatus(SPLITTING);
      } catch (err) {
        onFail(err, 'Erro ao enviar lista');
      }
    },
    [onFail, postUploadFile, file, setUploadStatus, startProgress]
  );

  useEffect(() => {
    if (uploadStatus === SPLITTING && progress > 15) {
      setUploadStatus(SEARCHING);
    }
  }, [progress, uploadStatus]);

  useEffect(() => {
    if (uploadData) {
      const { listId } = uploadData;
      pollListStatus(listId);
    }
  }, [uploadData, pollListStatus]);

  return {
    startUpload,
    uploadStatus,
    uploadProgress: progress,
    uploadError,
    uploadData,
    foundPercentage,
    getListLink,
  };
}
