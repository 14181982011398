import React from 'react';
import ReactModal from 'react-modal';

const style = {
  content: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    border: 'none',
    background: 'transparent',
    padding: 0,
    borderRadius: 0,
  },
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(10px)',
    textAlign: 'center',
    zIndex: 1000,
    borderRadius: 0,
  },
};

function BaseDialog({ dialog }) {
  return (
    <ReactModal style={style} isOpen={!!dialog}>
      {dialog}
    </ReactModal>
  );
}

ReactModal.setAppElement('#root');

export default BaseDialog;
