import React, { useEffect, useRef, useState } from 'react';

import { styled } from 'styles';

export default function NewToggleableComponent({ children, open }) {
  const [maxHeight, setMaxHeight] = useState('0px');
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setMaxHeight(open ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [open]);

  return (
    <Container ref={contentRef} style={{ maxHeight }}>
      {children}
    </Container>
  );
}

const Container = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease;
`;
