import React, { useCallback, useEffect, useMemo } from 'react';
import { MdUploadFile } from 'react-icons/md';
import { useDialogContext } from 'providers/DialogProvider';
import { media, styled } from 'styles';
import { useTracker } from 'modules/shared/hooks';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { useBannerContext } from 'providers/BannerProvider';
import useSkipBannerMaker from 'modules/banner/shared/hooks/useSkipBannerMaker';
import { useFeatureFlagContext } from 'providers/FeatureFlagProvider';

export default function SendExerciseListButton() {
  const { trackEvent } = useTracker();
  const { setDialog } = useDialogContext();
  const { hasFlagTempListUploadBanner } = useFeatureFlagContext();

  const { bannersToShowInfo } = useBannerContext();
  const skipBannerData = useSkipBannerMaker({
    bannerName: 'IA_LIGHT_25_1_VENDA_Web',
    views: 9,
    clicks: 2,
  });

  const shouldShowButton = useMemo(() => {
    return (
      bannersToShowInfo?.desktopFrontBannerName === 'desktopBannerMaker' &&
      !skipBannerData.shouldSkipBannerMaker()
    );
  }, [bannersToShowInfo, skipBannerData]);

  const hideButton = useMemo(() => {
    return !shouldShowButton && !hasFlagTempListUploadBanner();
  }, [shouldShowButton, hasFlagTempListUploadBanner]);

  const eventProperties = useMemo(() => {
    return {
      Onde: origin,
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Versão: '2.3',
    };
  }, []);

  const onClickBanner = useCallback(() => {
    trackEvent('MVP Lista: Clicou Banner', eventProperties);

    const utms = {
      utmSource: 'site_upload',
      utmMedium: 'banner',
      utmContent: 'pag_home',
    };

    setDialog(<UploadBookListDialog origin={origin} utms={utms} />);
  }, [trackEvent, eventProperties, setDialog]);

  useEffect(() => {
    if (!hideButton) {
      trackEvent('MVP Lista: Visualizou Banner', eventProperties);
    }
  }, [trackEvent, eventProperties, hideButton]);

  if (hideButton) return null;

  return (
    <Container onClick={onClickBanner}>
      <IconContainer>
        <MdUploadFile />
      </IconContainer>
      <TextContainer>
        <Title>
          Resolvemos sua lista de exercícios
          <br /> em 45 segundos
        </Title>
      </TextContainer>
      <Button>
        Quero minha lista
        <br /> resolvida
      </Button>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 110px;
  background-color: #bee8d1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  cursor: pointer;
  margin: 0 auto 40px auto;
  position: relative;
  overflow: hidden;
  color: #145314;

  @media ${media.mobile} {
    width: 95%;
    height: 70px;
    padding: 0 15px;
    margin: 0 auto 20px auto;
  }
`;

const IconContainer = styled.div`
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${media.mobile} {
    display: none;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  margin: 0 20px;

  @media ${media.mobile} {
    margin: 0;
  }
`;

const Title = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 5px;

  @media ${media.mobile} {
    font-size: 0.8rem;
  }
`;

const Button = styled.div`
  background-color: #ffffff;
  padding: 12px 30px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1.2rem;
  white-space: nowrap;
  text-align: center;

  br {
    display: none;
  }

  @media ${media.mobile} {
    padding: 8px 10px;
    font-size: 0.7rem;

    br {
      display: block;
    }
  }
`;
