import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import {
  GuideHeader,
  LecturesCards,
  SubjectsCards,
  BooksCards,
  CardsContent,
} from '../components';
import { Loading, Error } from 'modules/shared/components/generic';
import { useGuide } from '../hooks';
import { useTracker } from 'modules/shared/hooks';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { useThemesContext } from 'providers/ThemesProvider';

export default function StudyTrackPage() {
  const { studyTrackId, guideId } = useParams();
  const { setCurrentThemeId } = useThemesContext();

  const {
    data: studyTrack,
    loading: studyTrackLoading,
    error: studyTrackError,
  } = useContentQueryImmutable(`/study-tracks/${studyTrackId}`);

  const { data: guide, loading, error } = useContentQueryImmutable(
    `/guides/${guideId}`
  );
  const { trackEvent } = useTracker();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!studyTrack) return;
    setCurrentThemeId(studyTrack?.theme?.id);

    trackEvent('Visualizou Trilha de Estudo', {
      Matéria: studyTrack.theme.name,
      Nome: studyTrack.name,
    });
  }, [studyTrack, trackEvent, setCurrentThemeId]);

  const showLecturesCards = useCallback(lectures => {
    return lectures.length > 0;
  }, []);

  const showBooksCards = useCallback(books => {
    return books.length > 0;
  }, []);

  const { getLecturesFromSubjects, studyTrackGuidableName } = useGuide();

  if (studyTrackError || error) return <Error />;
  if (studyTrackLoading || loading) return <Loading />;

  const lectures = getLecturesFromSubjects(guide.subjects);

  return (
    <Container>
      <GuideHeader
        name={studyTrack.name}
        color={studyTrack.theme.color}
        utmContent={'pag_trilha_de_estudo'}
      />
      <CardsContent>
        {showLecturesCards(lectures) && (
          <LecturesCards
            lectures={lectures}
            color={studyTrack.theme.color}
            guideId={guide.id}
          />
        )}

        <SubjectsCards
          subjects={guide.subjects}
          color={studyTrack.theme.color}
          guideId={guide.id}
          guidableType={studyTrackGuidableName}
          name={studyTrack.name}
        />

        {showBooksCards(guide.books) && (
          <BooksCards books={guide.books} studyTrack={studyTrack.name} />
        )}
      </CardsContent>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;
