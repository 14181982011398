import React from 'react';
import { useUserContext } from 'providers/UserProvider';

export function UserMessage({ message }) {
  const { user } = useUserContext();

  return (
    <div className="flex items-start gap-0.5 justify-end">
      <div className="bg-orange-400 p-3 rounded-lg md:max-w-xs max-w-full text-white leading-tight text-base">
        {message}
      </div>
      <div className="w-12 h-12 flex flex-col items-center ml-1 mt-2">
        <img src={user.pictureUrl} className="w-8 h-8 rounded-full" />
        <p className="text-xs leading-none font-normal text-black mt-1 text-center">
          Você
        </p>
      </div>
    </div>
  );
}
