import React, { useState } from 'react';
import { useChatTrackEvents } from '../../../../../contexts/ListWithStatementQuestionPage';
import { ChatBox } from './ChatBox';
import { StepTitle } from './StepTitle';

export function Step({ index }) {
  const [showInfoBox, setShowInfoBox] = useState(false);
  const { trackFeatureClick, trackFeatureClose } = useChatTrackEvents();

  const handleInfoBoxToggle = () => {
    if (!showInfoBox) {
      trackFeatureClick(index);
    } else {
      trackFeatureClose(index);
    }
    setShowInfoBox(!showInfoBox);
  };

  return (
    <div className="flex flex-col gap-4">
      <StepTitle
        stepIndex={index}
        showInfoBox={showInfoBox}
        onInfoBoxToggle={handleInfoBoxToggle}
      />
      {showInfoBox && <ChatBox index={index} />}
    </div>
  );
}
